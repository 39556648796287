import axios from "axios";


export async function verificarPermissoesDrae(nomePermissao, contasPermissao, draeId, token) {
  if (contasPermissao !== null) {

    if (nomePermissao === "DRAE") {
      if (draeId !== undefined) {
        const unicaDrae = await buscarDraePeloId(draeId, token);
        const unicaDraeLista = [];
        unicaDraeLista.push(unicaDrae.data);
        return unicaDraeLista;
      }
    }
    const todasDraes = await buscarDraes(token);
    return todasDraes.data;

  } else {
    return null;
  }
}

async function buscarDraes(token) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/drae/buscarAtivos`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarDraePeloId(id, token) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/drae/buscarPorId/${id}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}
