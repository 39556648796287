import styled, { keyframes } from 'styled-components';

const entryButton = keyframes`
0% {
      transform: translateX(-50%);
    }
    100%{
      transform: translateX(0);
    }
`;

export const IconMenu = styled.div`
transition: 0.4s;
`;

export const ButtonStyle = styled.button`
  display: flex;
  background-color: ${props => props.isSelected ? "#F3FBFF" : "transparent"};
  color: ${props => props.isSelected && props.theme.color.primary ? props.theme.color.primary : props.theme.color.text[200]};
  font-weight: ${props => props.isSelected ? "600" : "500"};
  border: none;
  border-right: ${props => props.isSelected && props.theme.color.primary ?
    `1.5px solid ${props.theme.color.primary}` : "transparent"};
  width: 100%;  
  padding: 6% 0;
  align-items: center;
  justify-content: center;
  animation: ${entryButton} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.1s;

  &:hover {
    color: #0a7bbd;
    cursor: pointer;
    border-radius: 4%;
    background-color: #f2f2f2;

    ${IconMenu}{
      transform: translateX(20%);
    }

  }

  &:active {
    color: #02336b;
  }
   
   &:focus {
    outline: none;
  }
`;

export const AlignButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: all 0.1s ease-in-out;
  gap: 10%;
  width: 85%;

  &:hover {
    color: ${props => props.theme.color.primary};
  }
`;