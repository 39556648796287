import Rotas from './routes/Routes';
import styled from 'styled-components';
import { Toaster } from 'sonner';
import { ThemeProvider } from "styled-components";
import theme from './assets/theme/theme.js';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: 'Rawline', sans-serif;
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Toaster
          richColors
          position="top-right"
          visibleToasts={3}
          toastOptions={{
            style: { padding: "0.8rem" },
            duration: 2000,
            cancelButtonStyle: { display: "flex", backgroundColor: "#ff0000", borderRadius: "0.5rem" },
          }} />
        <Rotas />
      </AppContainer>
    </ThemeProvider>

  );
}

export default App;
