import { Container, Header } from "./InfoBox.style";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { IoInformationCircle } from "react-icons/io5";
export const InfoBox = ({ titulo, dado, message, onClick, ...props }) => {

    return (
        <Container
            onClick={onClick}>
            <Header>
                <p>{titulo.toUpperCase()} </p>
                <IoInformationCircle
                    size={18}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={message} />
                <Tooltip id="tooltip" delayShow={1} />
            </Header>
            <span>{dado}</span>
        </Container>
    );
}