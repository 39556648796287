import axios from "axios";

export async function verificarPermissoesDirec(nomePermissao, contasPermissao, direcId, token) {

  if (contasPermissao !== null) {
    if (nomePermissao === "DIREC") {
      if (direcId !== undefined) {
        const direcsPorId = await buscarDirecPeloId(direcId, token);
        const unicaDirecLista = [];
        unicaDirecLista.push(direcsPorId);
        return unicaDirecLista;
      }
    } 
      const todasDirecs = await buscarDirecs(token);
      return todasDirecs.data;
 
  } else {
    return null;
  }
}

async function buscarDirecPeloId(direcId, token) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/direc/buscarPorId/${direcId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarDirecs(token) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/direc/buscarAtivos`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data;
    })

    .catch((error) => {
      console.error(error);
    });
}
