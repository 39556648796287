import React from "react";
import { HeaderStyle } from "./Header.style";


export default function Header({ esconderMenu }) {
  return (
    <HeaderStyle>
      <div style={{display:'flex', alignItems:"end",width:"100%", height:"50%"}}>
        <p>SVS</p>
        <p style={{ textTransform: "uppercase", fontSize: "0.8rem", paddingLeft: "1rem" }}> Sistema de Verificação de Saldos</p>
        </div> 
    </HeaderStyle>
  );
}
