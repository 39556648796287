import axios from "axios";
import { toast } from "sonner";

type AuthResponse = {
    autenticador: boolean | null;
    url_qr_code: string | null;
    usuario: UserDetails | null;
    encryptedSecret: string | null;
}

type UserDetails = {
    id: string;
    role: string,
    relatorio: boolean;
    token: string;
    permissoes: Permissao[]
    nome: string;
    escola: string;
    direc: string,
    drae: string,
}

type Permissao = {
    nome: string;
    programaDirec: string;
    programaDrae: string;
    programaEscola: string;
};

export default class AuthService {
    token: string | null;
    url: string | null;
    headers: { authorization: string };

    constructor() {
        this.url = `${process.env.REACT_APP_FEE}/user` || null;
    }

    async signIn(cpf: string, password: string): Promise<any> {

        try {
            const response =
                await axios.post(`${this.url}/signin`, { cpf, password });
            const authReponse: AuthResponse = response.data;
           
            return authReponse;
        } catch (error) {
            toast.error(error.response.data.message);
            return;
        }

    }
}