import React, { useState, useEffect, useCallback } from "react";
import { TableBoxContainer, TableContainer } from "../../style/Adm.style.jsx";
import { useMyPermissoesContext } from "../../../../hooks/permissoes/MyPermissoesContext.jsx";
import AdicionarPopUp from "../popUps/AdicionarPopUp.jsx";
import { FaEdit } from "react-icons/fa";
import DirecsService from "../../../../services/DirecsService.ts";

export default function Direc() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);
  const [direcsTela, setDirecsTela] = useState([]);

  const { direcsPermitidas, setDirecsPermitidas } =
    useMyPermissoesContext();


  const direcService = new DirecsService();

  const listar = async () => {
    direcService.listar().then((response) => {
      setDirecsPermitidas(response.data);
    })
  };

  useEffect(() => {
    setDirecsTela(direcsPermitidas);
    listar();
  }, []);

  function fecharPopUp() {
    listar();
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  return (
    <TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..."
            onChange={
              (e) => {
                const search = e.target.value;
                const filtered = direcsPermitidas.filter(item => {
                  return item.nome.toLowerCase().includes(search.toLowerCase());
                });
                setDirecsTela(filtered);
              }
            } />
          <button className="button2" onClick={adicionarNovo}>
            + DIRECS
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            direcs={direcsPermitidas}
            fecharPopUp={fecharPopUp}
            tipo='direc'
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo='direc'
            direcs={direcsPermitidas}
            edicao={false}
          />
        )}

        <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de DIREC
          </td>
        </tr>
        <table style={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "start" }}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>FEE</th>
              <th>Município</th>
              <th>Convênio</th>
              <th>CNPJ</th>
              <th>Agência</th>
              <th>Conta</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {direcsTela.map((row, index) => (
              <tr key={index}>
                <td>{row.nome}</td>
                <td>{row.fee}</td>
                <td>{row.municipio}</td>
                <td>{row.convenio}</td>
                <td>{row.cnpj}</td>
                <td>{row.agencia}</td>
                <td>{row.conta}</td>
                <td>
                  <button style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer>
  );
}
