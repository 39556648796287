import React from 'react';
import * as S from './popUp.style'

const PopUp = ({ children, width, height }) => {


  return (
    <S.Modal>
      <S.ModalContent width={width} height={height}>
        {children}
      </S.ModalContent>
    </S.Modal>
  );
};

export default PopUp;