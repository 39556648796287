import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(100deg, #0fb9e4, #0a54b5);
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const FormContainer = styled.div`
  align-items: center;
  height: 100vw;
  width: 100vh;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.3);
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 425px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 95%;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
  margin-left: 5%;
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16%;
  margin-top: 2%;
  margin-bottom: 5%;
  background: #ddd9ce;
  border-radius: 10px;
  h1 {
    font-family: "Rawline", sans-serif;
    font-size: 210%;
    margin-top: 3%;
    color: #000;
  }
  h2 {
    font-family: "Open Sans, Semibold", sans-serif;
    font-size: 90%;
    color: #000;
  }
`;

export const FormLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  background-color: black;
`;

export const ImageContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  justify-content: flex-end;
`;

export const Image = styled.img`
  width: 45vw;
  height: 90vh;
  transform: scaleX(-1);
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const ContainerEsquerda = styled.div`
  flex: 1;
  width: 50vw;
  background: 'rgba(241, 180, 18, 0.15)';
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 1s;

  @media (max-width: 900px) {
    display:none;
    }

    &:hover {
      opacity: 50%;
    }
`;

export const ContainerDireita = styled.div`
  flex: 1;
  position: relative;
  background-color: white;
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-widht: 768px) {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(10, 89, 135, 1) 100%
    );
  }
`;

export const Form = styled.form`
  width: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 8%;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Campo = styled.div`
  width: 50%;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-family: "Rawline", sans-serif;
  font-size: 18px;
  color: #2465e2;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
  margin-left: -7%;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid gray;
  display: inline-block;
  padding: 0 20px;
  font-family: "Rawline", sans-serif;
  font-size: 16px;
  color: gray;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid gray;
  }
  &::placeholder {
    color: gray;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 25px;
  background-color: #0a5987;
  color: #fff;
  font-family: "Rawline", sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #2465e2;
  }
`;

export const Picture = styled.img`
  top: 0;
  left: 0;
  width: 50vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
`;

export const Logo = styled.img`
  margin-top: 60px;
  width: 50%;
  height: auto;
  object-fit: div;

  @media (max-width: 768px) {
    width: 60%;
  }
`;

export const Creditos = styled.p`
  display: flex;
  position: relative;
  font-family: "Rawline", sans-serif;
  font-size: 12px;
`;

export const FooterLogin = styled.footer`
  display: flex;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 10%;
  justify-content: center;
  align-items: center;
`;
