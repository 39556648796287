import React, { useState, useContext, createContext } from "react";
import { ConsultaRelatorio } from "./relatorio/consultarDados.js";
import { useMyPermissoesContext } from "./permissoes/MyPermissoesContext.jsx";

export const MyRelatorioContext = createContext({
  consultarDadosDirec: () => { },
  consultarDadosDrae: () => { },
  consultarDadosMaisAlimentacao: () => { },
  consultarDadosPagueOrdinario: () => { },
  consultarDadosPaguePredial: () => { },
});

export const useMyRelatorioContext = () => {
  return useContext(MyRelatorioContext);
};

export function MyRelatorioContextProvider({ children }) {

  const consultaRelatorio = new ConsultaRelatorio()
  const { direcsPermitidas, escolasPermitidas, draesPermitidas
  } = useMyPermissoesContext();

  async function consultarDadosDirec(cancelToken, handleProgress, token) {
    let result = await consultaRelatorio.execute("direc", direcsPermitidas, token, cancelToken, handleProgress)
    return result;
  }

  async function consultarDadosDrae(cancelToken, handleProgress, token) {
    const result = await consultaRelatorio.execute("drae", draesPermitidas, token, cancelToken, handleProgress)
    return result;
  }

  async function consultarDadosMaisAlimentacao(cancelToken, handleProgress, token) {

    const escolasMA = escolasPermitidas.filter((escola) => escola.convenio === "PAGUE MAIS ALIMENTACAO 000098")

    const result = await consultaRelatorio.execute("escola", escolasMA, token, cancelToken, handleProgress)
    return result;
  }

  async function consultarDadosPagueOrdinario(cancelToken, handleProgress, token) {
    const escolasPO = escolasPermitidas.filter((escola) => escola.convenio === "PAGUE ORDINARIO 000001")
    const result = await consultaRelatorio.execute("escola", escolasPO, token, cancelToken, handleProgress)
    return result;
  }

  async function consultarDadosPaguePredial(cancelToken, handleProgress, token) {
    const escolasPP = escolasPermitidas.filter((escola) => escola.convenio === "PAGUE PREDIAL 000097")

    const result = await consultaRelatorio.execute("escola", escolasPP, token, cancelToken, handleProgress)
    return result;
  }

  return (
    <MyRelatorioContext.Provider
      value={{
        consultarDadosDirec,
        consultarDadosDrae,
        consultarDadosMaisAlimentacao,
        consultarDadosPagueOrdinario,
        consultarDadosPaguePredial,
      }}
    >
      {children}
    </MyRelatorioContext.Provider>
  );
}
