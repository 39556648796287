import React, { useState, useContext, createContext, useEffect, useLayoutEffect } from "react";
import AuthService from "../services/AuthService.ts";
import { toast } from "sonner";
import axios from "axios";
import UsuarioService from "../services/UsuarioService.ts";

interface Permissao {
  nome: string;
}

interface User {
  id: string;
  ativo: boolean;
  email: string;
  nome: string;
  cpf: string;
  telefone: string;
  setor: string;
  role: 'USER' | 'ADMIN';
  relatorio: boolean;
  direc: {
    id: string;
  };
  drae: {
    id: string;
  };
  escola: {
    id: string;
  };
  autenticador: boolean;
  permissoes: Permissao[];
}

interface LoginResponse {
  autenticador: boolean;
  usuario: User;
  url_qr_code: string;
  encryptedSecret: string;
}

export const MyUserContext = createContext({
  signIn: async (cpf: string, password: string): Promise<LoginResponse | undefined> => undefined,
  usuarioLogado: null as User | null,
  token: null as string | null,
  usuariosNoSetor: [] as any[],
});

export const useMyUserContext = () => {
  return useContext(MyUserContext);
};

export function MyUserContextProvider({ children }) {
  const [usuarioLogado, setUsuarioLogado] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [usuariosNoSetor, setUsuariosNoSetor] = useState([]);



  const fetchUser = async () => {
    const storedToken = JSON.parse(sessionStorage.getItem('token') || 'null');

    if (storedToken) {
      try {
        const usuarioService = new UsuarioService();

        const response = await axios.get(`${process.env.REACT_APP_FEE}/token/getUsuario`,
          { headers: { Authorization: `Bearer ${storedToken}` }, }
        );


        setToken(storedToken);
        setUsuarioLogado(response.data);

        if (usuarioLogado) {
          const usuariosNoSetor = await usuarioService.listarPessoasPorSetor(usuarioLogado!.setor);
          setUsuariosNoSetor(usuariosNoSetor);
        }


      } catch (error) {
        toast.error("Sua sessão expirou, faça login novamente.");
        setToken(null);
        setUsuarioLogado(null);
        sessionStorage.removeItem("token");
      }
    }
  };


  useEffect(() => {
    fetchUser();
  }, [token]);



  async function signIn(cpf: string, password: string): Promise<LoginResponse | undefined> {
    try {
      var auth = new AuthService();
      const authReponse = await auth.signIn(cpf, password);

      const { autenticador,
        usuario,
        url_qr_code,
        encryptedSecret } = authReponse;

      setUsuarioLogado(usuario);

      setToken(usuario.token);

      sessionStorage.setItem("token", JSON.stringify(usuario.token));

      return {
        autenticador,
        usuario,
        url_qr_code,
        encryptedSecret
      }
    } catch (error) {
      return;
    }
  }
  return (
    <MyUserContext.Provider
      value={{
        signIn,
        usuarioLogado,
        token,
        usuariosNoSetor
      }}
    >
      {children}
    </MyUserContext.Provider>
  );
}
