import styled from 'styled-components';


export const CustomButton = styled.button`
    display: flex;
    background-color:${props => props.theme.color.primary};
    color: #F3FBFF;
    width: fit-content;
    min-height: 1rem;
    border: none;
     align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:hover {
        background-color:  #F3FBFF;
        color:  ${props => props.theme.color.primary};
    }
`;