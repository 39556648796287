import { CiLock } from "react-icons/ci";
import { FaTimes } from "react-icons/fa";
import { Button, Campo, Input } from "../requisition.style";
import axios from "axios";
import OTPInput from "react-otp-input";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const QrCode = ({ setModalAlterarSenha }) => {
  const [token, setToken] = useState("");

  const {
    register,
    handleSubmit
  } = useForm();
  const formRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function onSubmit(data) {
    const response = await axios.post(
      `${process.env.REACT_APP_FEE}/user/alterarSenha`,
      { cpfUsuario: data.cpf, tokenQrCode: token, novaSenha: data.novaSenha }
    );

    if (response.data.senhaAlterada) {
      toast.success("Senha alterada com sucesso!");
      setModalAlterarSenha(false);
    } else {
      toast.error("Erro ao alterar senha!");
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      formRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <button
        className="cancelButton"
        onClick={() => setModalAlterarSenha(false)}
      >
        <FaTimes color="" size={15} />
      </button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          marginBottom: "5%",
        }}
      >
        <div style={{ position: "relative", display: "flex" }}>
          <CiLock size={50} />
        </div>
        <p
          style={{ color: "#000000", fontSize: "1.2rem", margin: "2% 0 2% 0" }}
        >
          Esqueceu a Senha?
        </p>
      </div>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          alignItems: "center",
          width: "70%",
          gap: "10px",
          marginBottom: "4%",
        }}
      >
        <Input type="text" placeholder="CPF" {...register("cpf")} />
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Nova senha"
          minLength={6}
          {...register("novaSenha")}
        />
        <span
          onClick={togglePasswordVisibility}
          style={{
            cursor: "pointer",
            color: "#555",
            marginTop: "10px",
            position: "absolute",
            right: "10px",
            top: "55px",
            transform: "translateY(-10%)",
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </span>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p
          style={{
            color: "#494949",
            fontSize: "0.8rem",
          }}
        >
          Adicione o token gerado no seu aplicativo de autenticação
        </p>
        <Campo style={{ width: "100%", height: "auto", marginTop: "5%" }}>
          <OTPInput
            value={token}
            onChange={setToken}
            numInputs={6}
            renderSeparator={<span> . </span>}
            isInputNum={true}
            onKeyPress={handleKeyPress}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "2rem",
              height: "2rem",
              margin: "0 0.2rem 0 0",
              padding: "0.5rem",
              fontSize: "1.5rem",
              borderRadius: "4px",
              border: "1px solid rgba(0,0,0,0.3)",
            }}
          />
        </Campo>
      </div>
      <Button style={{ marginTop: "3%", width: "64%" }}>Alterar</Button>
    </div>
  );
};
