import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { Button } from "../../../../components/globalComponents.style.jsx";
import * as S from "../../style/form.style.jsx";
import { ContainerForm } from "../../style/form.style.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { HelperText } from "../../../../components/globalComponents.style.jsx";
import { toast } from 'sonner'
import { CiLock } from "react-icons/ci"
import PopUp from "../../../../components/PopUp/popUp.jsx";

const usuarioSchema = yup.object().shape({
  nome: yup.string().min(3, "Mínimo 3 caracteres para o nome").required("Nome obrigatório"),
  email: yup
    .string()
    .email("Email inválido")
    .min(6, "Mínimo 6 caracteres para o email")
    .required("Email obrigatório"),
  cpf: yup.string().required("Adicione um CPF válido").min(11, "Mínimo 11 caracteres para o cpf"),
  password: yup.string().min(6, "Mínimo 6 caracteres para a senha"),
  telefone: yup
    .string()
    .min(9, "Mínimo 9 caracteres para o telefone")
    .required("Telefone obrigatório"),
  setor: yup.string(),
  relatorio: yup.boolean().required(),
  autenticador: yup.boolean().required(),
  permissao: yup.string().required("Selecione uma permissão"),
});

const UserForm = ({ edicao, atual, url, fecharPopUp, permissoes, draes, direcs }) => {
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [resetarSecretPopUp, setResetarSecretPopUp] = useState(false);


  const permissaoChecked = atual?.permissoes[0].permissao.id;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(usuarioSchema),
    defaultValues: {
      nome: atual?.nome,
      email: atual?.email,
      cpf: atual?.cpf,
      setor: atual?.setor,
      relatorio: atual?.relatorio,
      autenticador: atual?.autenticador,
      password: atual?.password,
      telefone: atual?.telefone,
      permissao: permissaoChecked,
    },
    shouldUnregister: true,
  });


  const handleRadioChange = (permissao) => {
    setSelectedRadio(permissao);
  };

  const atualizar = async (atualizado) => {
    const response = sessionStorage.getItem("token");

    if (atualizado.draeId) {
      atualizado.setor = draes.find((drae) => drae.id === atualizado.draeId).nome;
      atualizado.direcId = null;
    } else if (atualizado.direcId) {
      atualizado.setor = direcs.find((direc) => direc.id === atualizado.direcId).nome;
      atualizado.draeId = null;
    }

    const usuarioRequest = {
      nome: atualizado.nome,
      email: atualizado.email,
      cpf: atualizado.cpf,
      setor: atualizado.setor || '',
      relatorio: atualizado.relatorio ? atualizado.relatorio : false,
      autenticador: atualizado.autenticador ? atualizado.autenticador : true,
      password: atualizado.password,
      telefone: atualizado.telefone ? atualizado.telefone : '',
      idPermissoes: selectedRadio ? selectedRadio.id : null,
      draeId: atualizado.draeId ? atualizado.draeId : null,
      direcId: atualizado.direcId ? atualizado.direcId : null,
      ...(atual && atual.id ? { id: atual.id } : {})
    };

    let user = '';

    try {
      user = edicao ? await axios.put(`${url}`, usuarioRequest,
        { headers: { authorization: `Bearer ${JSON.parse(response)}` } }) :
        await axios.post(`${url}`, usuarioRequest,
          { headers: { authorization: `Bearer ${JSON.parse(response)}` } })

      if (user.data.id) {
        fecharPopUp()
      }
    } catch (error) {
      alert("Erro ao cadastrar usuário", user)
    }
  }

  const labels = ["nome", "email", "cpf", "setor", "password", "telefone", "permissao", "relatorio", "autenticador", "secret"]

  function resetarSecret() {
    const response = sessionStorage.getItem("token");
    axios.post(`${process.env.REACT_APP_FEE}/user/resetarSecret`, { id: atual.id },
      { headers: { authorization: `Bearer ${JSON.parse(response)}` } })
      .then(() => {
        toast.success("Secret resetado com sucesso")
        setResetarSecretPopUp(false)
      })
      .catch(() => toast.error("Erro ao resetar secret"))
  }

  return (
    <ContainerForm>
      {resetarSecretPopUp ? <PopUp width={'40%'} height={'30%'}>
        <div style={{ width: "80%", height: "100%", justifyContent: "center", display: "flex", gap: "10%", flexDirection: "column", }}>
          <p style={{ color: "#6C6C6C", fontWeight: "bold" }}>Deseja realmente resetar o secret do usuário?</p>
          <p style={{ color: "#6C6C6C" }}>Após o reset, o usuário deverá realizar o cadastro do token ao se logar novamente.</p>

          <div style={{ display: "flex", gap: "3%" }}>
            <Button width="45%"
              type="button" onClick={() => resetarSecret()}
            > Sim </Button>
            <Button width="45%" primary
              type="button" onClick={() => setResetarSecretPopUp(false)}
            > Não </Button>
          </div>  </div></PopUp>
        : null}
      <div>

        <S.Label style={{ fontSize: "150%", marginBottom: "0.5rem" }}>
          {edicao ? "Atualizar usuário" : "Cadastrar usuário"}
        </S.Label>
        <p style={{ color: "#6C6C6C", fontWeight: "bold" }}>Perfil</p>
        <p style={{ color: "#6C6C6C", fontSize: "80%", marginTop: "2%" }}>Cadastre as informações do usuário ao lado</p>
      </div>
      <S.Form onSubmit={handleSubmit(atualizar)}>
        {edicao ? <label
          style={{
            flexDirection: "row",
            display: "flex",
            marginRight: "1rem",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            onChange={() => setShowPasswordField(!showPasswordField)}
          />Esqueceu a senha?</label>
          : null
        }
        {labels.map((label, index) => {
          return (
            <Fragment key={index}>
              {["nome", "telefone", "email"].includes(label) ? (
                <>
                  <S.LabeledInput
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    type="text"
                    name={label}
                    {...register(label)}
                  />
                  <HelperText>
                    {errors[label] && <p>{errors[label].message}</p>}
                  </HelperText>
                </>
              ) : label === "cpf" ? (
                <>
                  <S.LabeledInput
                    label="CPF (Somente números)"
                    type="text"
                    name="CPF"
                    {...register("cpf")}
                  />
                  <HelperText>
                    {errors.cpf && <p>{errors.cpf.message}</p>}
                  </HelperText>
                </>
              ) : label === "password" && (!edicao || showPasswordField) ? (
                <>
                  <S.LabeledInput
                    label="Senha"
                    type="password"
                    name="Senha"
                    {...register("password")}
                  />
                  <HelperText>
                    {errors.password && <p>{errors.password.message}</p>}
                  </HelperText>
                </>
              ) : label === 'relatorio' ? (

                <div style={{ gap: "2%", display: "flex" }}>
                  <S.Label htmlFor={label}> Acesso ao relatório? </S.Label>

                  <input
                    type="checkbox"
                    {...register("relatorio")}
                    defaultChecked={atual?.relatorio}
                  /> </div>

              ) : label === "secret" && edicao ? (
                <button style={{ width: "50%", height: "3rem", marginTop: "1rem", border: "none", backgroundColor: "white", color: "black", fontWeight: "bold", borderRadius: "5px", alignItems: "center", display: "flex", justifyContent: "center", gap: "1rem" }}
                  full type="button" onClick={() => setResetarSecretPopUp(true)}
                > <CiLock size={25} />Resetar secret </button>
              ) :
                label === 'autenticador' ? (

                  <div style={{ gap: "2%", display: "flex" }}>
                    <S.Label htmlFor={label}> Utilizar autenticador? </S.Label>

                    <input
                      type="checkbox"
                      {...register("autenticador")}
                      defaultChecked={atual?.autenticador}
                    /> </div>
                ) :
                  label === "permissao" ? (
                    <S.Campo>
                      <S.Label htmlFor={label}> Selecione uma permissão: </S.Label>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          width: "100%",
                          marginTop: "0.5rem",

                        }}
                      >
                        <fieldset style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                          {permissoes.map((permissao, index) => {
                            return (
                              <div key={index}>
                                <label
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    marginRight: "1rem",
                                    width: "fit-content",
                                    alignItems: "center",

                                  }}
                                >
                                  <input
                                    type="radio"
                                    value={permissao.id}
                                    onClick={() => handleRadioChange(permissao)}
                                    {...register(("permissao"), {
                                      setValueAs: (checked) =>
                                        checked ? permissaoChecked : null,
                                    })}
                                  />
                                  {permissao.nome}

                                </label>
                              </div>

                            );

                          })}

                        </fieldset>
                      </div>
                      <HelperText>
                        {errors[label] && <p style={{ fontSize: "1rem" }}>{errors[label].message}</p>}
                      </HelperText>
                      {selectedRadio && selectedRadio.nome === 'DIREC' ?
                        <S.LabeledSelect label="Escolha uma Direc"
                          type="text"
                          name="direcId"
                          {...register("direcId")}
                          id="direcId">
                          {direcs &&
                            direcs.map((direc, index) => {
                              return (
                                <option key={index} value={direc.id}>
                                  {direc.nome}
                                </option>
                              );
                            })}
                        </S.LabeledSelect> :
                        selectedRadio && selectedRadio.nome === 'DRAE' ?
                          <S.LabeledSelect label="Escolha uma Drae"
                            type="text"
                            name="draeId"
                            {...register("draeId")}
                            id="draeId"
                          >
                            {draes &&
                              draes.map((drae, index) => {
                                return (
                                  <option key={index} value={drae.id}>
                                    {drae.nome}
                                  </option>
                                );
                              })}
                          </S.LabeledSelect> :
                          <>
                            <S.LabeledInput
                              label="Digite seu setor"
                              type="text"
                              name='Digite seu setor'
                              {...register('setor')}
                            />
                            <HelperText>
                              {errors['setor'] && <p>{errors['setor'].message}</p>}
                            </HelperText>
                          </>
                      }
                    </S.Campo>

                  ) : null}

            </Fragment>
          );
        })}
        <Button width="50%" height="5%" type="submit">
          Adicionar
        </Button>
      </S.Form>
    </ContainerForm >
  );
};

export default UserForm;