import styled from 'styled-components';

export const Container = styled.div`
display: flex;
width: 100%;
height: 100%;
font-family: ${({ theme }) => theme.font.body};
padding: 0 2rem;
flex-direction: column;
`;

export const Section = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: ${({ height }) => height || '100%'};
gap: 1rem;
`;

export const Box = styled.div`
display: flex;
background-color: ${({ theme }) => theme.color.bg[200]};
width: ${({ width }) => width || '100%'};
height: ${({ height }) => height || '100%'};
padding: 1rem;
flex-direction: column;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
`;

export const ContainerBox = styled.div`
display: flex;
width: 100%;
height: 100%;
gap: 1rem;
justify-content: space-between;
`;

export const GridPerfil = styled.div`
display: grid;
grid-template-rows: 1fr 1fr;
gap: 1rem;
`;

export const ContainerInfo = styled.div`
display: flex;
width: 100%;
height: 20%;
justify-content: start;
`;