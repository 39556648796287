import styled from "styled-components";
import React, { useId, forwardRef, useState } from "react";


export const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 5%;
`;

export const Form = styled.form`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
`;

export const Select = styled.select`
  border: none;
  border-bottom: 1px solid #202020;
  color: #a9a9a9;
  display: flex;
  flex-shrink: calc();
  width: 100%;
  margin-top: 2%;
  font-size: small;
  background-color: white;

  &:focus {
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }

  &::placeholder {
    color: #a9a9a9;
    font-size: small;
    background-clip: text;
  }
`;

export const LabeledSelect = forwardRef(
  ({ label, multiple, type, name, ...props }, ref) => {
    const inputId = useId();

    return (
      <>
        <Container>
          <Label htmlFor={inputId}>{label}</Label>
          <Select ref={ref} id={inputId} type={type} name={name} {...props} />
        </Container>
      </>
    );
  }
);

export const Label = styled.label`
  font-family: "Inter", sans - serif;
  font-size: 16px;
  color: #0a5987;
  width: fit-content;
  padding-bottom: 1%;
  font-weight: 600;
  text-align: left;
`;

export const HelperText = styled.label`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #202020;
  display: flex;
  position: sticky;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #000000;
  transition: 1s;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #5c5eff;
  }
  &::placeholder {
    color: #a9a9a9;
    font-size: small;
    align-items: start;
  }
`;

export const LabeledInput = forwardRef(
  ({ label, type, name, ...props }, ref) => {
    const inputId = useId();

    return (
      <>
        <Container>
          <Label htmlFor={inputId}>{label}</Label>
          <Input ref={ref} id={inputId} type={type} name={name} {...props} />
        </Container>
      </>
    );
  }
);

export const LabeledBoxInput = styled(LabeledInput)`
  height: 80px;
  margin-bottom: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  transition: 2s;
  text-align: top;
  font-size: small;

  &::placeholder {
    text-align: top;
    color: #a9a9a9;
    display: flex;
    padding: 10px;
    background: white;
    font-size: 12px;
    background-clip: text;
  }
  &:focus {
    outline: none;
    border: none;
    border: 1px solid #0a5987;
  }
`;

export const Option = styled.option`
  padding: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1%;
  & {
    margin-right: 20px;
  }
`;

export const Image = styled.img`
  width: 26vw;
  height: auto;
  object-fit: cover;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 50vw;
  }
`;

export const Title = styled.h1`
  font-family: "Inter", sans-serif;
  color: #0a5987;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;




export const Colunas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2%;
  width: 80%;
  height: auto;
  align-items: flex-start;
`;

export const Campo = styled.div`
  flex-direction: column;
  width: 40%;
  padding-bottom: 5%;
  margin: 0;
  height: fit-content;
`;

export const Teste = styled.label`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  font-size: small;
  &:focus {
    outline: none;
    border: none;
    border: 1px solid #0a5987;
  }
`;

const FormStyles = {
  Label,
  Colunas,
  HelperText,
  Select,
  LabeledSelect,
  LabeledBoxInput,
  LabeledInput,
  Input,
  Form,
  ContainerForm,
  Option,
  Image,
  Title,
  Teste,
};

// Estilize o componente InputArray conforme necessário
const StyledInputArray = styled.input`
  /* Adicione estilos conforme necessário */
`;

export const InputArray = ({ value = [], onChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value.join(", "));

  const handleInputChange = (e) => {
    if (e && e.target) {
      setInputValue(e.target.value);
      const arrayValues = e.target.value
        .split(",")
        .map((value) => value.trim());
      if (typeof onChange === "function") {
        onChange(arrayValues);
      }
    }
  };

  return (
    <StyledInputArray
      {...props}
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

export default FormStyles;
