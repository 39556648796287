import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { MyRelatorioContextProvider } from "../hooks/MyRelatorioContext";
import { MyPermissoesContextProvider } from "../hooks/permissoes/MyPermissoesContext";

function Rotas() {
  return (

    <MyPermissoesContextProvider>
      <MyRelatorioContextProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Carregando...</div>}>
            <Routes>
              <Route path="/home" exact element={<Layout />} />
              <Route path="/" exact element={<Login />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </MyRelatorioContextProvider>
    </MyPermissoesContextProvider>

  );
}

export default Rotas;
