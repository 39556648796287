import styled from 'styled-components';


export const HeaderExtrato = styled.div`
    display: flex;
justify-content: space-between;
    width: 100%;
    height: 20%;
    background-color: ${props => props.theme.color.bg[200]};
    border-bottom: 1px solid ${props => props.theme.color.text[300]};
    shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    align-items: start;
    padding: 0.3rem 1rem;

    p { 
    wrap: nowrap;
    white-space: nowrap;
        font-size: 0.9rem;
        padding: 0.5rem;
    }

    span { 
    color: ${props => props.theme.color.primary};
    font-size: 1.2rem;
    margin: 0.5rem 0;
    text-transform: uppercase;
    font-weight: bold;
    }

    button { 
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    }
    `;

export const FormConsultaExtrato = styled.form`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60%;
    align-items: end;
    margin: 0.5rem 0;
    `;