import { ContainerTitle, H1, H2, Separator } from "./Title.style";

function Title({title}) {
    return (
      <ContainerTitle>
        <H1>{title}</H1>
        <Separator/>
      </ContainerTitle>
    );
  
  }
  
  export default Title;
  