import React from 'react';
import { Container, Label } from './ContainerBoxLabel.style.tsx';

interface ContainerBoxLabelProps {
  title: string;
  content: any;
}

interface BoxLabelProps {
  title: string;
  color: "preto" | "cinza";
  bold: boolean;
}

export default function ContainerBoxLabel({ title, content }: ContainerBoxLabelProps) {
  return (
    <Container>
      <BoxLabel title={title} color="cinza" bold={true} />
      <BoxLabel title={content} color="preto" bold={false} />
    </Container>

  );
}

function BoxLabel({ title, color, bold }: BoxLabelProps) {
  return (
    <Label color={color} bold={bold}>
      {title}
    </Label>
  );
}