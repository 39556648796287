
import Table from 'react-bootstrap/Table';
import { H1 } from '../globalComponents.style';
import '../ExtratoFundoInvestimento/extrato.css';

function ResponsiveExample(props) {
  var soma = 0;

  return (
    <div >
      <H1 >
        Extrato Bancário - Convênio {props.data.governmentProgramName}
      </H1>
      {(props.data.data === "sem dados") ?
        <p className='title'>
          Sem lançamentos no período
        </p> :
        <table>
          <thead>
            <tr>
              <th>Dt. Balancete</th>
              <th>Histórico</th>
              <th>Documento</th>
              <th>Valor</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody >
            {props.data.transactions.map((dado) => {
              if (dado.creditDebitIndicator === "D") {
                soma = soma - dado.value
              } else {
                soma = soma + dado.value
              }
              return (
                <tr key={dado.id}>
                  <td>{dado.valueDate}</td>
                  <td>{dado.descriptionCode} {dado.descriptionName} {dado.beneficiaryDocumentId} {dado.beneficiaryName}</td>
                  <td>{dado.referenceNumber}</td>
                  <td>{dado.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })} {dado.creditDebitIndicator}</td>
                  <td>{soma.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}C</td>
                </tr>
              )
            }
            )}
          </tbody>
        </table>
      }
    </div >
  );
}

export default ResponsiveExample;