const theme = {
    color: {
        bg: {
            100: '#F2F2F2',
            200: '#FFFFFF',
            300: '#F9F9F9',
        },
        text: {
            100: '#161616',
            200: '#677677',
            300: '#E7E7E7'
        },
        primary:' #0A7BBD'
    },
    font: {
        body: 'Rawline, sans-serif',
    },
    fontSize: {
        h3: {
            fontSize: '2.25rem',
            lineHeight: '2.25rem',
        }
    },
    dimension: {
        maxWidth: '1200px',
    },
    zIndex: {
        stepProgress: '100',
        bottomBar: '550',
        backdrop: '990',
        modal: '1000'
    }
}

export default theme;