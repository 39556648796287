import styled from 'styled-components';

export const Container = styled.button`
 display: flex;
 flex-direction: column;
 width: 100%;
height: 100%;
border: 1px solid #E7E7E7;
height: 5rem;
appearance: none;
background-color: transparent;
width: 14rem;
font-size: 0.8rem;
margin:0 0.5rem;
color: ${props => props.theme.color.text[200]};

&:hover { 
    background-color: ${props => props.theme.color.bg[100]};
    cursor: pointer;
}

span {
    padding: 0.5rem;
    font-size: 1.3rem;
    color: ${props => props.theme.color.primary};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

 `;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    p { 
    wrap: nowrap;
    white-space: nowrap;
        font-size: 0.9rem;
        padding: 0.5rem;
    }
    `;
