import styled from 'styled-components';


export const ContainerTitle = styled.div`
display: flex;
width: 100%;
height: 2rem;
justify-content: space-between;
align-items: center;
margin-top: 1rem;
`;

export const H1 = styled.h1`
display: flex;
font-size: 2rem;
width: fit-content;
flex-wrap: nowrap;
white-space: nowrap;
color: ${({ theme }) => theme.color.primary || '#0A7BBD'} !important;
text-transform: uppercase;
padding: 0 -2rem; 
 `;

export const Separator = styled.div`
width: 100%;
display: flex;
border-bottom: 1px solid #292929;
`;