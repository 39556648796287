import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(5px);
  transition: 0.5s;

  &:hover {
  backdrop-filter: blur(10px);
  }
  `;

export const ModalContent = styled.div`
  width: ${props => props.width || 'fit-content'};
  height: ${props => props.height || '80vh'};
  background: white;
  border-radius: 10px;
  position: relative;
  overflow: auto;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10%;
  padding:3%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.219);
`;
