import axios from "axios";
const token = JSON.parse(sessionStorage.getItem("token"));
export async function verificarPermissoesEscola(
  nomePermissao,
  direc,
  drae,
  programaEscola,
  token
) {

  let escolasRetornadas = [];
  let escolasPaguePredial = [];
  let escolasPagueOrdinario = [];
  let escolasMaisAlimentação = [];

  if (programaEscola !== null || programaEscola !== "") {
    if (programaEscola?.includes("pp")) {
      if (nomePermissao === "CORE") {
        escolasPaguePredial = await buscarEscolasPorPrograma(
          "PAGUE PREDIAL 000097",
          token
        );
      } else if (nomePermissao === "DIREC") {
        escolasPaguePredial = await buscarEscolaPorProgramaDirec(
          direc,
          "PAGUE PREDIAL 000097",
          token
        );
      }
      else if (nomePermissao === "DRAE") {
        escolasPaguePredial = await buscarEscolaPorProgramaDrae(
          drae,
          "PAGUE PREDIAL 000097",
          token
        );
      }
    }

    if (programaEscola?.includes("po")) {
      if (nomePermissao === "CORE") {
        escolasPagueOrdinario = await buscarEscolasPorPrograma(
          "PAGUE ORDINARIO 000001",
          token
        );
      } else if (nomePermissao === "DIREC") {
        escolasPagueOrdinario = await buscarEscolaPorProgramaDirec(
          direc,
          "PAGUE ORDINARIO 000001",
          token
        );
      }
      else if (nomePermissao === "DRAE") {
        escolasPagueOrdinario = await buscarEscolaPorProgramaDrae(
          drae,
          "PAGUE ORDINARIO 000001",
          token
        );
      }
    }

    if (programaEscola?.includes("ma")) {
      if (nomePermissao === "DRAE") {
        escolasMaisAlimentação = await buscarEscolaPorProgramaDrae(
          drae,
          "PAGUE MAIS ALIMENTACAO 000098",
          token
        );
      } else if (nomePermissao === "SUASE") {
        escolasMaisAlimentação = await buscarEscolasPorPrograma(
          "PAGUE MAIS ALIMENTACAO 000098",
          token
        );
      } else if (nomePermissao == "DIREC") {
        escolasMaisAlimentação = await buscarEscolaPorProgramaDirec(
          direc,
          "PAGUE MAIS ALIMENTACAO 000098",
          token
        );

      }

      if (nomePermissao === "ADMIN" || nomePermissao === "FEE") {
        let todas = await buscarEscolas(token);
        return todas;
      }
    }

    escolasRetornadas = escolasPaguePredial.concat(
      escolasPagueOrdinario,
      escolasMaisAlimentação
    );

    return escolasRetornadas;
  } else {
    return null;
  }
}

async function buscarEscolaPorProgramaDrae(draeId, convenio, token) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenioDrae/${draeId}/${convenio}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolaPorProgramaDirec(direcId, convenio, token) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenioDirec/${direcId}/${convenio}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolasPorPrograma(convenio, token) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenio/${convenio}/`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolas(token) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/escola/buscarAtivos`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}
