import { CiLock } from "react-icons/ci";
import { FaTimes } from "react-icons/fa";
import { Button, Input } from "../requisition.style";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const SenhaAntiga = ({ setModalAlterarSenha }) => {
  const {
    register,
    handleSubmit
  } = useForm();

  const [showPasswordAntiga, setShowPasswordAntiga] = useState(false);
  const [showPasswordNova, setShowPasswordNova] = useState(false);

  const togglePasswordVisibilityAntiga = () => {
    setShowPasswordAntiga(!showPasswordAntiga);
  };

  const togglePasswordVisibilityNova = () => {
    setShowPasswordNova(!showPasswordNova);
  };

  async function onSubmit(data) {
    const idUsuario = JSON.parse(sessionStorage.getItem("token")).usuario.id;
    const response = await axios.post(
      `${process.env.REACT_APP_FEE}/user/alterarSenha`,
      {
        idUsuario,
        novaSenha: data.novaSenha,
        senhaAntiga: data.senhaAntiga,
      }
    );
    if (response.data.senhaAlterada) {
      toast.success(response.data.message);
      setModalAlterarSenha(false);
    } else {
      toast.error(response.data.message);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          height: "100%",
          padding: "5%",
        }}
      >
        <button
          className="cancelButton"
          onClick={() => setModalAlterarSenha(false)}
        >
          <FaTimes color="" size={15} />
        </button>
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "0.8%",
            bottom: "10%",
          }}
        >
          <CiLock size={50} />
          <p style={{ color: "#000000", fontSize: "1.2rem", marginBottom: "24px" }}>Alterar Senha</p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            width: "70%",
            gap: "10px",
          }}
        >
          <div style={{ position: "relative", width: "100%" }}>
            <Input
              type={showPasswordAntiga ? "text" : "password"}
              placeholder="Senha Antiga"
              minLength={6}
              {...register("senhaAntiga")}

              style={{ width: "100%" }}
            />
            <span
              onClick={togglePasswordVisibilityAntiga}
              style={{
                cursor: "pointer",
                color: "#555",
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <FontAwesomeIcon icon={showPasswordAntiga ? faEyeSlash : faEye} />
            </span>
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <Input
              type={showPasswordNova ? "text" : "password"}
              placeholder="Nova Senha"
              minLength={6}
              {...register("novaSenha")}
              style={{ width: "100%" }}
            />
            <span
              onClick={togglePasswordVisibilityNova}
              style={{
                cursor: "pointer",
                color: "#555",
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <FontAwesomeIcon icon={showPasswordNova ? faEyeSlash : faEye} />
            </span>
          </div>
          <Button style={{ marginTop: "24px", width: "60%", alignSelf: "center" }}>Alterar</Button>
        </form>
      </div>
    </>
  );
};
