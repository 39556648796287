import DraeDirecForm from "./DraeDirecForm.jsx";
import EscolaForm from "./EscolaForm.jsx";
import UserForm from "./UserForm.jsx";
import PermissaoForm from "./PermissaoForm.jsx";

const routes = {
  direc: "direc",
  drae: "drae",
  escola: "escola",
  usuario: "user",
  permissao: "permissao",
};

const FormAdicionar = ({
  edicao,
  atual,
  draes,
  direcs,
  fecharPopUp,
  tipo,
  permissoes,
}) => {

  const verificarURL = () => {
    if (!edicao) {
      return routes[tipo]
        ? `${process.env.REACT_APP_FEE}/${routes[tipo]}/`
        : null;
    } else {
      return routes[tipo]
        ? `${process.env.REACT_APP_FEE}/${routes[tipo]}/atualizarPorId/${atual.id}`
        : null;
    }
  };

  return (
    <>
      {tipo && (tipo === "direc" || tipo === "drae") ? (
        <DraeDirecForm
          atual={atual}
          tipo={tipo}
          edicao={edicao}
          url={verificarURL()}
          fecharPopUp={fecharPopUp}
        />
      ) : tipo && tipo === "escola" ? (
        <EscolaForm
          atual={atual}
          edicao={edicao}
          url={verificarURL()}
          draes={draes}
          direcs={direcs}
          fecharPopUp={fecharPopUp}
        />
      ) : tipo && tipo === "usuario" ? (
        <UserForm
          atual={atual}
          edicao={edicao}
          draes={draes}
          direcs={direcs}
          url={verificarURL()}
          permissoes={permissoes}
          fecharPopUp={fecharPopUp}
        />
      ) : tipo && tipo === "permissao" ? (
        <PermissaoForm
          atual={atual}
          edicao={edicao}
          url={verificarURL()}
          fecharPopUp={fecharPopUp}
        />
      ) : null}
    </>
  );
};

export default FormAdicionar;
