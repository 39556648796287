import styled from "styled-components";

export const Tabela = styled.table`
  width: 100%;
  height: 100%;
  display: table;
  box-sizing: border-box;
  border-collapse: collapse;
`;

export const TabelaHeader = styled.thead`
  position: sticky;
  top:10%;
  z-index:1;
  
  background-color: #f7f8fa;
  
  display: flex;
  
`;

export const TabelaHeaderCell = styled.th`
  display: flex;
  font-weight: bold;
  flex-wrap: nowrap;
  padding-top: 1.5rem;
  white-space: nowrap;
  
  justify-content: center;
  height: 100%;
  width: 100%;
  width: ${props => props.width || null};
`;

export const TabelaDataCell = styled.td`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: ${props => props.width || null};
`;


export const TabelaBody = styled.tbody`
  color: #0a7bbd;
  background-color: white;
  width: 100%;
`;

export const TabelaRow = styled.tr`
  color: black;
  display: flex;
  background-color: white !important;
  height: 100%;
  width: 100%;
  padding:  0.5rem 0;
  align-items: center;
  &:nth-child(even) {
    background-color: #f3f3f3 !important;
  }
`;



export const TabelaButton = styled.button`
  background-color: #0a7bbd;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
