import styled from 'styled-components';

export const MenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  width: ${props => props.esconderMenu ? "15vw" : "5vw"};
  transition: all 0.5s ease-in-out;
  position: relative;
  top: 0;
  padding-top: 1%;
  left: 0;
  background-color: ${props => props.theme.color.bg[200]};
  box-shadow: -11px -8px 15px 6px rgba(0, 0, 0, 0.25);
  z-index: 10;
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 1rem 0;
width: 100%;
`;

export const Divisoria = styled.div`
width: 100%;
border-top: 0.5px solid #c0c0c09d;
`;

export const EsconderMenu = styled.div`
  border: none;
display: flex;
position: absolute;
  right: -0.5rem;
  background-color: white;
  top: 4rem;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 1rem;
`;

export const ContainerLogoEWelcome = styled.div`
  display: flex;
  width: 100%;
  height: 15%;
  align-items: center;
  justify-content: center;
  `;

export const Logo = styled.img`
  width: fit-content;
  transition: all 0.5s ease-in-out;
  height: ${props => props.esconderMenu ? "8vh" : "6vh"};
  margin: ${props => props.esconderMenu ? "2%" : "5vh"};
  top: 10;
  left: 0;
`;

export const Welcome = styled.h2`
  font-family: 'Rawline', sans-serif;
  font-size: 16px;
  gap: 5px;
  flex-direction: column;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  color: black;
  display:  ${props => props.esconderMenu ? "flex" : "none"};
  flex-wrap: nowrap;
  transition: all 0.5s ease-in-out;
  justify-content: center;
`;

export const LogOut = styled.button`
  background-color: transparent;
  color: #0A7BBD;
  border: none;
  font-size: 18px;
  justify-content: center;
  align-items:center ;
  text-align: center; 
  transition: 0.4s;
  margin-top:10%;
  width: 33%;
  height: 6%;

  &:hover {
    color: #0A5987;
    cursor: pointer;
    border-radius: 8px;
    background-color: #f2f2f2;
    
  }
`;

export const LineMenu = styled.div`
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  margin-bottom: 8px;
`;

export const AlterarSenhaButton = styled.button`
background-color: transparent;
font-size: 80%;
border: none;
margin-bottom: 2%;
color: #0A7BBD;

&:hover {
  text-decoration: underline;
}
`;

export const ContainerAlterarSenha = styled.div`
display: flex;
align-items: center;
justify-content: center;
color:#0A7BBD;

@media screen and (max-width: 768px) {
  flex-direction: column;
}
`;