import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'sonner';

// Define a configuração de fonte uma vez
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function extratoPDF({ dados, selectedOption }) {

    if (!dados) {
        toast.warning('Não há dados da consulta para gerar PDF.');
        return;
    }
    var soma = 0;
    const dadosMapeados = dados.transactions.map((item) => {
        if (item.creditDebitIndicator === 'D') {
            soma -= item.value;
        } else {
            soma += item.value;
        }

        // Adiciona a lógica para alternar cores de fundo
        const fillColor = item.id % 2 === 0 ? '#DDDDDD' : '#FFFFFF';

        return {
            stack: [
                { text: `${item.valueDate}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 12 },
                { text: `${item.descriptionCode} ${item.descriptionName} ${item.beneficiaryDocumentId} ${item.beneficiaryName}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 12 },
                { text: `${item.referenceNumber}`, width: 'auto', fillColor: fillColor, fontSize: 12 },
                { text: `${item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })} ${item.creditDebitIndicator}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 12 },
                { text: `${soma.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })} C`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 12 },
            ],
            _minWidth: 101.162109375, // Ajuste conforme necessário
            _maxWidth: 101.162109375, // Ajuste conforme necessário
        };
    });

    const docDefinition = {
        // ... outras configurações ...
        content: [
            {
                stack: [
                    { bold: true, text: `${selectedOption.nome} - ${selectedOption.municipio}`, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 5] },
                    { text: `CNPJ: ${selectedOption.cnpj} - Ag: ${selectedOption.agencia} Conta: ${selectedOption.conta} `, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 5] },
                    { text: `Extrato bancário convênio - ${selectedOption.convenio}`, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 10] },
                ],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['20%', '20%', '20%', '20%', '20%'], // Ajuste conforme necessário
                    body: [
                        [
                            { text: 'Dt. Balancete', style: 'tableHeader', fontSize: 12 },
                            { text: 'Histórico', style: 'tableHeader', fontSize: 12 },
                            { text: 'Documento', style: 'tableHeader', fontSize: 12 },
                            { text: 'Valor R$', style: 'tableHeader', fontSize: 12 },
                            { text: 'Saldo', style: 'tableHeader', fontSize: 12 },
                        ],
                        ...dadosMapeados.map(entry => entry.stack),
                    ],
                    layout: 'lightHorizontalLines',
                    alignment: 'center', // Centraliza a tabela na folha
                },
            },
        ],
    };

    // Restante do código...

    pdfMake.createPdf(docDefinition).open();
}

export default extratoPDF;
