import React, { useEffect, useState } from "react";
import { ButtonStyle, AlignButton, IconMenu } from "./Button.style.jsx";
import { MdWork, MdSchool, MdAutoGraph, MdBorderColor } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";
import { useMyUserContext } from "../../../hooks/MyUserContext.tsx";
import { IoHomeOutline } from "react-icons/io5";
import { TbZoomMoney } from "react-icons/tb";
export default function ButtonsMenu({ setBotaoSelecionado, esconderMenu, setIsSelected, isSelected }) {

  const { direcsPermitidas, escolasPermitidas, draesPermitidas } = useMyPermissoesContext();

  const { usuarioLogado } = useMyUserContext();


  useEffect(() => {

  }, [direcsPermitidas, escolasPermitidas, draesPermitidas]);

  return (
    <>

      <ButtonStyle
        isSelected={isSelected === 'PÁGINA INICIAL'
        }
        onClick={() => {
          setIsSelected('PÁGINA INICIAL');
          setBotaoSelecionado('PÁGINA INICIAL');
        }}
      >
        <AlignButton esconderMenu={esconderMenu}>
          <IconMenu> <IoHomeOutline /></IconMenu>
          {esconderMenu ? 'PÁGINA INICIAL' : null}
        </AlignButton>
      </ButtonStyle >


      <ButtonStyle
        isSelected={isSelected === 'EXTRATO'
        }
        onClick={() => {
          setIsSelected('EXTRATO');
          setBotaoSelecionado('EXTRATO');
        }}
      >
        <AlignButton esconderMenu={esconderMenu}>
          <IconMenu> <TbZoomMoney size={20} /></IconMenu>
          {esconderMenu ? 'EXTRATO' : null}
        </AlignButton>
      </ButtonStyle >

      {usuarioLogado?.relatorio === true ?
        <ButtonStyle
          isSelected={isSelected === 'RELATÓRIO'
          }
          onClick={() => {
            setIsSelected('RELATÓRIO');
            setBotaoSelecionado('RELATÓRIO');
          }}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu><HiOutlineDocumentReport /></IconMenu>
            {esconderMenu ? 'RELATÓRIO' : null}
          </AlignButton>
        </ButtonStyle >
        : null
      }

      {usuarioLogado?.permissoes[0]?.nome === 'ADMIN' ?
        <ButtonStyle
          isSelected={isSelected === 'ADM'
          }
          onClick={() => {
            setIsSelected('ADM');
            setBotaoSelecionado('ADM');
          }}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu> <MdBorderColor /></IconMenu>
            {esconderMenu ? 'ADM' : null}
          </AlignButton>
        </ButtonStyle >
        : null}
    </>
  );
}