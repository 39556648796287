import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MenuStyle,
  Welcome,
  LogOut,
  LineMenu,
  Logo,
  EsconderMenu,
  AlterarSenhaButton,
  ContainerAlterarSenha,
  Container,
  ContainerLogoEWelcome,
  Divisoria
} from "./Menu.style.jsx";
import ButtonsMenu from "./Button/ButtonsHome.jsx";
import { GrDocumentConfig } from "react-icons/gr";
import { IoMdLogOut } from "react-icons/io";
import ImagemSEEC from "../../img/SEEC - vertical - logo.png";
import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { SenhaAntiga } from "../../pages/Login/AlterarSenha/SenhaAntiga.jsx";
import PopUp from "../PopUp/popUp.jsx";
import { AlignButton, ButtonStyle, IconMenu } from "./Button/Button.style.jsx";

export default function Menu({
  nome,
  setBotaoSelecionado,
  esconderMenu,
  setEsconderMenu,
}) {
  const navigate = useNavigate();
  const { permissoes, apagarTodasAsPermissoes, direcsPermitidas, escolasPermitidas, draesPermitidas } = useMyPermissoesContext();
  const [alterarSenhaPopUp, setAlterarSenhaPopUp] = useState(false);
  const [isSelected, setIsSelected] = useState(1);

  const primeiroNome =
    nome?.split(" ")[0].charAt(0).toUpperCase() +
    nome?.split(" ")[0].slice(1).toLowerCase();

  useEffect(() => {
  }, [direcsPermitidas, escolasPermitidas, draesPermitidas]);

  return (
    <MenuStyle esconderMenu={esconderMenu}>
      {alterarSenhaPopUp && (
        <PopUp width={"30%"} height={"fit-content"}
        ><SenhaAntiga setModalAlterarSenha={() => setAlterarSenhaPopUp()} /></PopUp>
      )}
      <EsconderMenu
        esconderMenu={esconderMenu}
        onClick={() => setEsconderMenu(!esconderMenu)}>
        {esconderMenu ? (
          <RiMenuFoldLine size={25} color={"#0A7BBD"} />) : (
          <RiMenuUnfoldLine size={25} color={"#0A7BBD"} />
        )}
      </EsconderMenu>
      <ContainerLogoEWelcome>

        <Logo src={ImagemSEEC} alt="Logo SEEC" esconderMenu={esconderMenu} onClick={() => window.location.reload()} />
        <Welcome esconderMenu={esconderMenu}>
          <span style={{ color: 'gray', fontSize: "14px", fontWeight: "normal", fontWeight: "bold" }}>Gestor {permissoes[0]}</span>
          <span>
            {nome?.length > 20 ? primeiroNome : nome}
          </span>
        </Welcome>
      </ContainerLogoEWelcome>

      <Container>
        <span style={{ paddingLeft: "1rem", fontSize: "12px", color: "gray", marginBottom: "1%" }}>HOME</span>
        <ButtonsMenu
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          esconderMenu={esconderMenu}
          setBotaoSelecionado={setBotaoSelecionado}
        />
      </Container>

      <Container>
        <span style={{ paddingLeft: "1rem", fontSize: "12px", color: "gray" }}>AJUSTE</span>
        <ButtonStyle

          onClick={() => setAlterarSenhaPopUp(true)}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu> <GrDocumentConfig /></IconMenu>
            {esconderMenu ? 'SEGURANÇA' : null}
          </AlignButton>
        </ButtonStyle >
      </Container>

      <Container>
        <span style={{ paddingLeft: "1rem", fontSize: "12px", color: "gray" }}>CONTA</span>
        <ButtonStyle

          onClick={() => {
            sessionStorage.clear();
            apagarTodasAsPermissoes()
            navigate("/");
          }}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu>  <IoMdLogOut /></IconMenu>
            {esconderMenu ? 'SAIR' : null}
          </AlignButton>
        </ButtonStyle >
      </Container>

    </MenuStyle >
  );
}
