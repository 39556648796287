import { useForm } from "react-hook-form";
import axios from "axios";
import { Button } from "../../../../components/globalComponents.style";
import * as S from "../../style/form.style.jsx";
import { ContainerForm } from "../../style/form.style.jsx";

const DraeDirecForm = ({ tipo, atual, url, fecharPopUp, edicao }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: atual,
  });

  const direcDrae = [
    "cnpj",
    "nome",
    "agencia",
    "fee",
    "conta",
    "municipio",
    "convenio",
  ];

  const atualizar = async (atualizado) => {
    const response = sessionStorage.getItem("token");

    try {
      edicao ? await axios.put(`${url}`, atualizado,
        { headers: { authorization: `Bearer ${JSON.parse(response)}` } }) :
        await axios.post(`${url}`, atualizado,
          { headers: { authorization: `Bearer ${JSON.parse(response)}` } })
      fecharPopUp();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerForm>
      <div>
        <S.Label style={{ fontSize: "150%", marginBottom: "0.5rem" }}>
          {edicao
            ? `Atualizar ${tipo.toUpperCase()}`
            : `Cadastrar ${tipo.toUpperCase()}`}
        </S.Label>
        <p style={{ color: "#6C6C6C", fontWeight: "bold" }}>Informações</p>
        <p style={{ color: "#6C6C6C", fontSize: "80%", marginTop: "2%" }}>
          Cadastre as informações da {tipo.toUpperCase()} ao lado
        </p>
      </div>
      <S.Form onSubmit={handleSubmit(atualizar)}>
        <S.Colunas
          style={{
            width: "80%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <S.Teste style={{ width: "50%", height: "100%", gap: "1.5rem" }}>
            {direcDrae.map((campo, index) => {
              if (campo !== "id") {
                return (
                  <S.LabeledInput
                    label={campo.charAt(0).toUpperCase() + campo.slice(1)}
                    type="text"
                    name={campo}
                    key={index}
                    {...register(campo)}
                  />
                );
              }
              return null;
            })}
          </S.Teste>
          <Button style={{ width: "50%", height: "2rem" }}>Adicionar</Button>
        </S.Colunas>
      </S.Form>
    </ContainerForm>
  );
};

export default DraeDirecForm;
