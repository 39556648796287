import axios from "axios";
import { toast } from "sonner";

async function Consultar({ tipo, entidades, token, cancelToken, onProgress }) {
  let valor = [];
  let totalRequisicoesCC = 0;
  let totalRequisicoesFinanceiro = 0;

  console.log("Consultando saldos...");

  if (token !== "" && entidades) {
    // Array de progresso para armazenar o progresso das duas requisições por entidade
    let progressArray = new Array(entidades.length * 2).fill(0);

    const promises = entidades.map(async (obj, index) => {
      const { agencia, conta } = obj;

      let data = JSON.stringify({
        agencia: agencia.replace(/.$/, ""),
        conta: conta.replace(/.$/, ""),
      });

      let urlAppFinanceira = `${process.env.REACT_APP_FEE}/bb/SaldoAplicacaoFinanceira/`;
      let urlContaCorrente = `${process.env.REACT_APP_FEE}/bb/SaldoContaCorrente/`;

      const consultarResultados = async (url, requestIndex) => {
        if (url === urlContaCorrente) totalRequisicoesCC++;
        if (url === urlAppFinanceira) totalRequisicoesFinanceiro++;

        if (data && data.agencia !== null && data.conta !== "") {
          try {
            const valorConsultado = await axios.post(url, data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              cancelToken: cancelToken,

              onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / (progressEvent.total)
                );
                progressArray[requestIndex] = percentCompleted;

                let totalProgress =
                  progressArray.reduce((a, b) => a + b, 0) / (progressArray.length);


                onProgress(totalProgress);
              },
            });

            return valorConsultado;
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log("Request canceled", error.message);
            } else if (error.response && error.response.status === 400) {
              console.log("Error 400 encountered, retrying...", obj.nome, error.response.data);
              toast.info("Erro ao consultar saldo de " + obj.nome + ", tentando novamente...");
              return consultarResultados(url, requestIndex); // Tente novamente
            } else {
              console.error(error);
            }
            return 0;
          }
        }
        return 0;
      };

      let SaldoAplicacaoFinanceira;
      let SaldoContaCorrente;

      // O índice para progresso de SaldoContaCorrente
      const contaCorrenteIndex = index * 2;
      // O índice para progresso de SaldoAplicacaoFinanceira
      const appFinanceiraIndex = index * 2 + 1;

      SaldoContaCorrente = await consultarResultados(urlContaCorrente, contaCorrenteIndex);

      SaldoAplicacaoFinanceira = await consultarResultados(urlAppFinanceira, appFinanceiraIndex);


      if (SaldoAplicacaoFinanceira || SaldoContaCorrente) {
        if (tipo === "direc" || tipo === "drae") {
          valor.push({
            id: obj.id,
            nome: obj.nome,
            municipio: obj.municipio,
            saldoAppFinanceira: SaldoAplicacaoFinanceira.data?.valorDisponibilidade,
            saldoContaCorrente: SaldoContaCorrente.data?.valorDisponibilidade,
          });
        } else if (tipo === "escola") {
          valor.push({
            id: obj.id,
            nome: obj.nome,
            municipio: obj.municipio,
            cnpj: obj.cnpj,
            direc: obj.direc.nome,
            drae: obj.drae.nome,
            saldoAppFinanceira: SaldoAplicacaoFinanceira.data?.valorDisponibilidade,
            saldoContaCorrente: SaldoContaCorrente.data?.valorDisponibilidade,
          });
        }
      }
    });

    await Promise.all(promises);
  }

  return valor;
}

export default Consultar;
