import React, { useState, useEffect, useCallback } from "react";
import { TableBoxContainer, TableContainer } from "../../style/Adm.style.jsx";
import { FaEdit } from "react-icons/fa";
import { useMyPermissoesContext } from "../../../../hooks/permissoes/MyPermissoesContext.jsx";
import "./table.css"
import AdicionarPopUp from "../../components/popUps/AdicionarPopUp.jsx";
import UsuarioService from "../../../../services/UsuarioService.ts";
import PermissoesService from "../../../../services/PermissoesService.ts";

export function Usuario() {
  const dados = sessionStorage.getItem("token");

  const [usuarios, setUsuarios] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);
  const [usuariosNaTela, setUsuariosNaTela] = useState([]);

  const [loading, setLoading] = useState(false);

  const { draesPermitidas, direcsPermitidas } =
    useMyPermissoesContext();

  const usuarioService = new UsuarioService();
  const permissoesService = new PermissoesService();

  const listarUsuarios = async () => {
    setLoading(true)
    usuarioService.listarUsuarios().then((response) => {
      setUsuarios(response.data);
      setLoading(false)
    })
  };

  const listarPermissoes = async () => {
    setLoading(true)
    permissoesService.listarPermissoes().then((response) => {
      setPermissoes(response.data);
      setLoading(false)
    })
  };

  useEffect(() => {
    listarUsuarios();
    listarPermissoes();

  }, []);

  useEffect(() => {
    setUsuariosNaTela(usuarios);
  }, [usuarios]);

  function fecharPopUp() {
    listarUsuarios();
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  return (<>
    {loading ? <div>loading</div> : (<TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..."
            onChange={(e) => {
              const search = e.target.value;
              const filtered = usuarios.filter(item => {
                return item.nome.toLowerCase().includes(search.toLowerCase());
              });
              setUsuariosNaTela(filtered);
            }} />
          <button className="button2" onClick={adicionarNovo}>
            + USUÁRIO
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            draes={draesPermitidas}
            direcs={direcsPermitidas}
            permissoes={permissoes}
            fecharPopUp={fecharPopUp}
            tipo={"usuario"}
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo={"usuario"}
            draes={draesPermitidas}
            direcs={direcsPermitidas}
            permissoes={permissoes}
            edicao={false}
          />
        )}
        <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de USUÁRIOS
          </td>
        </tr>
        <table style={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "start" }}>
          <thead>

            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Email</th>
              <th>CPF</th>
              <th>Setor</th>
              <th>Telefone</th>
              <th>Relatório</th>
              <th>Permissões</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {usuariosNaTela.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.nome}</td>
                <td>{row.email}</td>
                <td>{row.cpf}</td>
                <td>{row.setor ? row.setor : "Não aplica"}</td>
                <td>{row.telefone}</td>
                <td>{row.relatorio ? 'Acesso' : 'Sem acesso'}</td>
                <td>
                  {row.permissoes?.map((data, index) => (
                    <span key={index}>{data.permissao.nome}, </span>
                  ))}
                </td>
                <td>
                  <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer>)}
  </>
  );
}

export default Usuario;
