import React, { useRef, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import { BodyStyle } from "../Home/Home.style.jsx";
import { Button } from "../../components/globalComponents.style.jsx";
import Table from "./components/Table/Table.jsx";
import { IoFilter } from "react-icons/io5";
import * as Popover from "@radix-ui/react-popover";
import { IoPrintSharp } from "react-icons/io5";
import ProgressBar from "react-bootstrap/ProgressBar";

import "bootstrap/dist/css/bootstrap.css";

import { extratoPDFDirecOrDrae } from "../../components/Relatorio/RelatorioPDFDirecOrDrae.jsx";
import extratoPDFEscola from "../../components/Relatorio/RelatorioPDFEscola.jsx";
import Select from "react-select";

import {
  HeaderTitle,
  BoxContainer,
  InfoBox,
  PopoverContent,
  RelatorioContainer,
  MenuItem,
  Fieldset,
  MenuContainer,
  Text,
  PopoverArrow,
  UtilsContainer,
  MenuItemsContainer,
  UtilsButton,
  InfoContainer,
  ImprimirContainer,
} from "./Relatorio.style.jsx";
import { useMyRelatorioContext } from "../../hooks/MyRelatorioContext.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import Header from "../../components/Header/Header.jsx";

const Relatorio = ({
}) => {
  const {
    consultarDadosDirec,
    consultarDadosDrae,
    consultarDadosMaisAlimentacao,
    consultarDadosPagueOrdinario,
    consultarDadosPaguePredial,
  } = useMyRelatorioContext()

  const { direcsPermitidas, escolasPermitidas, draesPermitidas
  } = useMyPermissoesContext();

  const [state, setState] = useState({
    botaoSelecionado: '',
    totalContaCorrente: 0,
    totalAplicacao: 0,
    loading: false,
  })

  const [dados, setDados] = useState({
    consultadosDirec: [],
    consultadosDrae: [],
    consultadosEMA: [],
    consultadosEPP: [],
    consultadosEPO: [],
    dadosConsultados: [],
    dadosEmTela: [],
  })

  const [selectedDirecs, setSelectedDirecs] = useState([]);
  const [selectedDraes, setSelectedDraes] = useState([]);
  const [selectedEscolas, setSelectedEscolas] = useState([]);
  const [filteredEscolas, setFilteredEscolas] = useState(escolasPermitidas);
  const [escolasNoFiltro, setEscolasNoFiltro] = useState();
  const [direcsNoFiltro, setDirecsNoFiltro] = useState();
  const [draesNoFiltro, setDraesNoFiltro] = useState();
  const [progress, setProgress] = useState();

  const cancelToken = useRef(axios.CancelToken.source());
  const { usuarioLogado } = useMyUserContext();

  const permissaoUsuario = usuarioLogado.permissoes[0]?.nome;

  const handleProgress = (progress) => {
    setProgress(progress);
  }

  useEffect(() => {
    if (state.botaoSelecionado === 'escola-mais-alimentacao'
      || state.botaoSelecionado === 'escola-pague-predial'
      || state.botaoSelecionado === 'escola-pague-ordinario') {
      if (dados?.dadosEmTela) {
        const direcsNoFiltroMap = dados.dadosEmTela.map((escola) => escola.direc);
        const direcsUnicas = [...new Set(direcsNoFiltroMap)];
        setDirecsNoFiltro(direcsUnicas);

        const draesNoFiltroMap = dados.dadosEmTela.map((escola) => escola.drae);
        const draesUnicas = [...new Set(draesNoFiltroMap)];
        setDraesNoFiltro(draesUnicas);

        const escolasNoFiltroMap = dados.dadosEmTela.map((escola) => { return { nome: escola.nome, cnpj: escola.cnpj } });
        const escolasUnicas = [...new Set(escolasNoFiltroMap)];
        setEscolasNoFiltro(escolasUnicas);
      }
    }
  }, [dados.dadosEmTela])

  const token = JSON.parse(sessionStorage.getItem('token'))

  //função repassada para a Table.jsx para que calcule o total da conta corrente e retorne para o relatório
  function getTotalContaCorrente(total) {
    setState((prevState) => ({
      ...prevState,
      totalContaCorrente: total,
    }))
  }

  //função repassada para a Table.jsx para que calcule o total da aplicação e retorne para o relatório
  function getTotalAplicacao(total) {
    setState((prevState) => ({
      ...prevState,
      totalAplicacao: total,
    }))
  }

  async function fetchData(dataKey, consultFunction, cancelToken) {
    try {
      const result = await consultFunction(cancelToken, handleProgress, token);

      setDados((prevState) => ({
        ...prevState,
        [dataKey]: result,
        dadosConsultados: result,
      }))

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }))

    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        console.error(err);
      }

      setDados((prevState) => ({
        ...prevState,
        dadosConsultados: dados.dadosConsultados,
      }));

      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }

  const conditions = [
    { tipo: 'direc', data: "consultadosDirec", length: direcsPermitidas?.length },
    { tipo: 'drae', data: "consultadosDrae", length: draesPermitidas?.length },
    { tipo: 'escola-mais-alimentacao', data: "consultadosEMA", length: 585 },
    { tipo: 'escola-pague-predial', data: "consultadosEPP", length: 586 },
    { tipo: 'escola-pague-ordinario', data: "consultadosEPO", length: 585 },
  ]

  function verificarSeExistemDados(tipo) {
    const condition = conditions.find((condition) => condition.tipo == tipo);

    if (
      condition &&
      dados[condition.data] &&
      dados[condition.data].length == condition.length
    ) {
      return dados[condition.data]
    }
    return false
  }

  const consultar = useCallback(
    async (tipo, atualizar) => {

      let consultarDados = verificarSeExistemDados(tipo);

      if (consultarDados && !atualizar) {
        if (cancelToken.current) {
          cancelToken.current.cancel("Solicitação cancelada pelo usuário.");
        }

        cancelToken.current = axios.CancelToken.source();

        setDados((prevState) => ({
          ...prevState,
          dadosConsultados: consultarDados,
        }));

        setState((prevState) => ({
          ...prevState,
          botaoSelecionado: tipo,
          loading: false,
        }));
      } else {
        if (state.loading === false) {
          setDados((prevState) => ({
            ...prevState,
            dadosConsultados: [],
          }));
          setState((prevState) => ({
            ...prevState,
            loading: true,
            botaoSelecionado: tipo,
            totalAplicacao: 0,
            totalContaCorrente: 0,
          }))

          switch (tipo) {
            case 'direc':
              fetchData(
                "consultadosDirec",
                consultarDadosDirec,
                cancelToken.current.token
              );
              break;
            case 'drae':
              fetchData(
                "consultadosDrae",
                consultarDadosDrae,
                cancelToken.current.token
              );
              break;
            case 'escola-mais-alimentacao':
              fetchData(
                "consultadosEMA",
                consultarDadosMaisAlimentacao,
                cancelToken.current.token
              );
              break;
            case 'escola-pague-ordinario':
              fetchData(
                "consultadosEPP",
                consultarDadosPagueOrdinario,
                cancelToken.current.token
              );

              break;
            case 'escola-pague-predial':
              fetchData(
                "consultadosEPO",
                consultarDadosPaguePredial,
                cancelToken.current.token
              );
              break;
            default:
              setState((prevState) => ({
                ...prevState,
                loading: false,
              }));
              break;
          }
        } else {
          toast.warning("Aguarde o carregamento");
          return;
        }
      }
    },
    [
      state.botaoSelecionado,
      dados.dadosConsultados,
      dados.consultadosDirec,
      dados.consultadosDrae,
      dados.consultadosEMA,
      dados.consultadosEPP,
      dados.consultadosEPO,
    ]
  )


  async function gerarRelatorioPDF() {
    var tipo = "";
    if (state.botaoSelecionado === 'direc') {
      tipo = "Direc";
    } else if (state.botaoSelecionado === 'drae') {
      tipo = "Drae";
    } else if (state.botaoSelecionado === 'escola-mais-alimentacao') {
      tipo = "Escola Mais Alimentação";
    } else if (state.botaoSelecionado === 'escola-pague-predial') {
      tipo = "Escola Pague Predial";
    } else {
      tipo = "Escola Pague Ordinário";
    }

    if (!tipo.includes("Escola")) {
      extratoPDFDirecOrDrae(
        tipo,
        dados.dadosEmTela,
        state.totalAplicacao,
        state.totalContaCorrente
      );
    } else {
      extratoPDFEscola(
        tipo,
        dados.dadosEmTela,
        state.totalAplicacao,
        state.totalContaCorrente
      );
    }
  }

  return (
    <>
      <Popover.Root>
        <BodyStyle>
          <HeaderTitle title="ADM" />
          <BoxContainer>
            <InfoContainer>
              <InfoBox
                titulo={
                  state.botaoSelecionado === 'direc'
                    ? "Quantidade Direcs"
                    : state.botaoSelecionado === 'drae'
                      ? "Quantidade Draes"
                      : "Quantidade Escolas"
                }
                valor={dados?.dadosEmTela?.length}
              />
              <InfoBox
                titulo="TOTAL CONTA CORRENTE"
                valor={state.totalContaCorrente.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              />
              <InfoBox
                titulo="TOTAL APLICAÇÃO"
                valor={state.totalAplicacao.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              />
              <InfoBox
                titulo="TOTAL"
                valor={(
                  state.totalAplicacao + state.totalContaCorrente
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              />
            </InfoContainer>
            <InfoBox titulo="" valor="" />
          </BoxContainer>

          <ImprimirContainer>
            <Button
              height="35px"
              width="10%"
              onClick={() => gerarRelatorioPDF()}
            >
              {" "}
              <IoPrintSharp /> Imprimir
            </Button>
          </ImprimirContainer>

          <RelatorioContainer>
            <MenuContainer>
              <MenuItemsContainer>
                {(!(permissaoUsuario === "DIREC") && !(permissaoUsuario === "DRAE") && !(permissaoUsuario === "SUASE")) &&
                  <MenuItem
                    botaoSelecionado={state.botaoSelecionado === 'direc'}
                    onClick={() => consultar('direc')}>
                    Direc
                  </MenuItem>}
                {(!(permissaoUsuario === "DRAE") && !(permissaoUsuario === "DIREC") && !(permissaoUsuario === "CORE")) &&
                  < MenuItem
                    botaoSelecionado={state.botaoSelecionado === 'drae'}
                    onClick={() => consultar('drae')}>
                    Drae
                  </MenuItem>
                }
                {(!(permissaoUsuario === "DIREC") && !(permissaoUsuario === "CORE")) &&
                  <MenuItem
                    botaoSelecionado={state.botaoSelecionado === 'escola-mais-alimentacao'}
                    onClick={() => consultar('escola-mais-alimentacao')}>
                    Escola MA
                  </MenuItem>}

                {(!(permissaoUsuario === "DRAE") && !(permissaoUsuario === "SUASE")) &&
                  <MenuItem
                    botaoSelecionado={state.botaoSelecionado === 'escola-pague-predial'}
                    onClick={() => consultar('escola-pague-predial')}>
                    Escola PP
                  </MenuItem>
                }
                {(!(permissaoUsuario === "DRAE") && !(permissaoUsuario === "SUASE")) &&
                  <MenuItem
                    botaoSelecionado={state.botaoSelecionado === 'escola-pague-ordinario'}
                    onClick={() => consultar('escola-pague-ordinario')}>
                    Escola PO
                  </MenuItem>}
              </MenuItemsContainer>

              <UtilsContainer>
                <UtilsButton
                  type="button"
                  onClick={() => consultar(state.botaoSelecionado, true)}
                >
                  Atualizar
                </UtilsButton>
                <Popover.Trigger asChild>
                  <UtilsButton primary>Filtro</UtilsButton>
                </Popover.Trigger>
              </UtilsContainer>
            </MenuContainer>

            <Popover.Portal>
              <PopoverContent sideOffset={3}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <Text>
                    <IoFilter /> Filtre sua busca
                    <div
                      style={{ borderTop: "1px solid gray", padding: "5px 0" }}
                    />
                  </Text>

                  {!(permissaoUsuario === "DIREC") &&
                    state.botaoSelecionado === 'direc' ||
                    state.botaoSelecionado === 'escola-mais-alimentacao' ||
                    state.botaoSelecionado === 'escola-pague-predial' ||
                    state.botaoSelecionado === 'escola-pague-ordinario' ? (
                    <Fieldset>
                      <p>Direcs</p>
                      <Select
                        value={selectedDirecs}
                        isMulti
                        placeholder="Selecione as Direcs"
                        options={
                          Array.isArray(direcsNoFiltro) && direcsNoFiltro.length > 0
                            ? direcsNoFiltro.map((direc) => ({
                              value: direc,
                              label: direc,
                            }))
                            : []
                        }
                        onChange={setSelectedDirecs}
                      />
                    </Fieldset>
                  ) : null}

                  {!(permissaoUsuario === "DRAE") &&
                    state.botaoSelecionado === 'drae' ||
                    state.botaoSelecionado === 'escola-mais-alimentacao' ||
                    state.botaoSelecionado === 'escola-pague-predial' ||
                    state.botaoSelecionado === 'escola-pague-ordinario' ? (
                    <Fieldset>
                      <p>Draes</p>
                      <Select
                        value={selectedDraes}
                        isMulti
                        placeholder="Selecione as Draes"
                        options={
                          Array.isArray(draesNoFiltro) && draesNoFiltro.length > 0
                            ? draesNoFiltro.map((drae) => ({
                              value: drae,
                              label: drae,
                            }))
                            : []
                        }
                        onChange={setSelectedDraes}
                      />
                    </Fieldset>
                  ) : null}

                  {state.botaoSelecionado === 'escola-mais-alimentacao' ||
                    state.botaoSelecionado === 'escola-pague-predial' ||
                    state.botaoSelecionado === 'escola-pague-ordinario' ? (
                    <Fieldset>
                      <p>Escolas</p>
                      <Select
                        value={selectedEscolas}
                        isMulti
                        placeholder="Selecione as Escolas"
                        styles={{ width: "100%", display: "flex", position: "relative" }}
                        options={
                          Array.isArray(escolasNoFiltro) && escolasNoFiltro.length > 0
                            ? escolasNoFiltro.map((escola) => ({
                              value: escola.nome,
                              label: `${escola.nome} - ${escola.cnpj}`,
                            }))
                            : []
                        }
                        onChange={setSelectedEscolas}
                      />
                    </Fieldset>
                  ) : null}
                </div>

                <PopoverArrow />
              </PopoverContent>
            </Popover.Portal>

            {state.loading ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "90%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "0.1rem"
                  }}
                >
                  <p>Aguarde, por favor...</p>
                  <ProgressBar
                    now={progress}
                    animated
                    label={`Aguarde, por favor...${progress}%`}
                    visuallyHidden
                    style={{ width: "50%" }}
                  />
                </div>
              </>
            ) : (
              <Table
                opcao={state.botaoSelecionado}
                dadosConsultados={dados.dadosConsultados}
                getTotalContaCorrente={getTotalContaCorrente}
                getTotalAplicacao={getTotalAplicacao}
                filteredEscolas={selectedEscolas}
                filteredDirecs={selectedDirecs}
                filteredDraes={selectedDraes}
                dadosEmTela={dados.dadosEmTela}
                setDadosEmTela={setDados}
              />
            )}
          </RelatorioContainer>
        </BodyStyle>
      </Popover.Root >
    </>
  );
};

export default Relatorio;
