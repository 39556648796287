import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import { Container, BodyStyle, ContainerMenuEConteudo, ContainerHeaderEConteudo, HeaderStyle } from "./Home.style.jsx";
import Admin from "../Adm/Adm.jsx";
import Relatorio from "../Relatorios/Relatorio.jsx";
import lock from "../../assets/lock.svg";

import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { HeaderTitle } from "../Relatorios/Relatorio.style.jsx";
import Dashboard from "../Dashboard/Dashboard.tsx";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import FormConsulta2 from "../../components/FomularioConsulta/FormConsulta2.jsx";

function Layout({ nome }) {

  const { token } = useMyUserContext();

  const [botaoSelecionado, setBotaoSelecionado] = useState("");

  const { usuarioLogado } = useMyUserContext();

  const {
    verificarPermissoesDoUsuario
  } = useMyPermissoesContext();

  useEffect(() => {
    if (usuarioLogado) {
      verificarPermissoesDoUsuario()
    }
  }, [usuarioLogado]);

  const [esconderMenu, setEsconderMenu] = useState(true);
  if (token !== null) {
    return (
      <>

        <Container esconderMenu={esconderMenu} relatorio={botaoSelecionado === "RELATORIO" ? true : false}>

          <ContainerMenuEConteudo>
            <Menu
              nome={usuarioLogado?.nome}
              setBotaoSelecionado={setBotaoSelecionado}
              esconderMenu={esconderMenu}
              setEsconderMenu={setEsconderMenu} />
            {/*HEADER + BODY*/}
            <ContainerHeaderEConteudo>
              <Header esconderMenu={esconderMenu} titulo={nome} />
              <BodyStyle
                relatorio={botaoSelecionado === "RELATORIO" ? true : false}
              >


                {botaoSelecionado === "ADM" ? (
                  <Admin />
                ) : botaoSelecionado === "EXTRATO" ? (
                  <FormConsulta2 />
                ) : botaoSelecionado === "RELATÓRIO" ? (
                  <Relatorio />
                ) : botaoSelecionado === "PÁGINA INICIAL" ? (
                  <Dashboard />
                ) : (
                  <Dashboard />
                )
                }
              </BodyStyle >
            </ContainerHeaderEConteudo>
          </ContainerMenuEConteudo>
        </Container >

      </>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "5%", width: "100vw" }}>
        <h1>Usuário não logado</h1>
        <Link to="/"> Clique aqui para acessar a página de login</Link>
        <img src={lock} />
      </div>
    );
  }
}

export default Layout;
