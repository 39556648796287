import styled from 'styled-components';

export const Label = styled.p<{color: string, bold: boolean}>`
  color: ${({color, theme}) => color === 'preto' ? theme.color.text[100] : theme.color.text[200]};
  font-size: 0.8rem;
  font-weight: ${({bold}) => bold ? '700' : '600'};
`;

export const Container = styled.div`
 display: flex;
 flex-direction: column;
gap: 0.3rem;
 `;