import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import teste2 from "../../img/teste2.jpeg";
import { FaTimes } from "react-icons/fa";
import logo from "../../img/SEEC - vertical.png";
import { toast } from "sonner";
import {
    Container,
    ContainerEsquerda,
    ContainerDireita,
    Form,
    Campo,
    Label,
    Input,
    Button,
    Picture,
    Logo,
    Creditos,
    FooterLogin,
} from "./requisition.style.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import PopUp from "../../components/PopUp/popUp.jsx";
import { QrCode } from "./AlterarSenha/QRCode.jsx";
import { CiLock } from "react-icons/ci";
import OTPInput from "react-otp-input";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";


function Login() {

    const { apagarTodasAsPermissoes } = useMyPermissoesContext();

    const [cpf, setCpf] = useState("");
    const [password, setPassword] = useState("");
    const [autenticador, setAutenticador] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const navigate = useNavigate();
    const [flag] = useState(true);
    const [otp, setOtp] = useState("");
    const [token, setToken] = useState("");
    const [encryptedSecret, setEncryptedSecret] = useState();

    const [modalAlterarSenha, setModalAlterarSenha] = useState(false);

    const { usuarioLogado, signIn } = useMyUserContext();

    useEffect(() => {
        apagarTodasAsPermissoes();
        setAutenticador(false);
        setQrCode("");
        setToken("");
        setOtp("");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setPassword("");

        try {
            const { autenticador, usuario, url_qr_code, encryptedSecret }
                = await signIn(cpf, password);

            setEncryptedSecret(encryptedSecret);

            if (autenticador === true) {
                setAutenticador(true);
                if (url_qr_code) {
                    setQrCode(url_qr_code);
                }
            } else {
                if (usuario) {
                    toast.success("Login efetuado com sucesso!");
                   
                    navigate("/home");
                }
            }
        } catch (error) {
            return;
        }
    };

    const onChangeCpf = (e) => {
        setCpf(e.target.value);
    };
    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    function fecharPopUp() {
        setAutenticador(false);
    }

    async function VerificarQRCode() {
        if (token.length >= 6) {
            const response = await axios.post(
                `${process.env.REACT_APP_FEE}/user/verificarQrCode`,
                {
                    cpfUsuario: cpf,
                    tokenQrCode: token,
                    encryptedSecret: encryptedSecret,
                }
            );
            if (response.data.status === true) {
                toast.success(response.data.message);
                navigate("/home");
            } else {
                toast.error(response.data.message);
            }
        } else {
            return;
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            VerificarQRCode();
        }
    };

    return (
        <>
            {modalAlterarSenha && (
                <PopUp width={"30%"} height={"60%"}>
                    <QrCode setModalAlterarSenha={setModalAlterarSenha} />
                </PopUp>
            )}
            {autenticador && (
                <>
                    <PopUp width={"30%"} height={"69%"}>
                        <button
                            className="cancelButton"
                            onClick={() => setAutenticador(false)}
                        >
                            <FaTimes color="" size={15} />
                        </button>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                marginBottom: "2rem",
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CiLock size={50} style={{ alignSelf: "center" }} />
                                {qrCode && <img src={qrCode} alt="QR Code" />}

                                <div
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        borderRadius: "50%",
                                        backgroundColor: "#c7c7c7",
                                        position: "absolute",
                                        zIndex: -1,
                                    }}
                                ></div>
                            </div>
                            <p
                                style={{
                                    color: "#000000",
                                    fontSize: "1.2rem",
                                    marginTop: "1%",
                                }}
                            >
                                Verificar Token
                            </p>
                            <p
                                style={{
                                    color: "#3b3b3b",
                                    fontSize: "0.9rem",
                                    marginTop: "2%",
                                }}
                            >
                                Adicione o token gerado no seu aplicativo de autenticação
                            </p>
                        </div>
                        <Campo>
                            <OTPInput
                                value={token}
                                onChange={setToken}
                                numInputs={6}
                                renderSeparator={<span> . </span>}
                                isInputNum={true}
                                required
                                renderInput={(props) => <input {...props} onKeyPress={handleKeyPress} />}
                                inputStyle={{
                                    width: "2rem",
                                    height: "2rem",
                                    margin: "0 0.2% 0 0",
                                    padding: "0.5rem",
                                    fontSize: "1.5rem",
                                    borderRadius: "4px",
                                    border: "1px solid rgba(0,0,0,0.3)",
                                }}
                            />
                        </Campo>
                        {token.length < 6 && token.length > 1 && (
                            <p style={{ color: "red", fontSize: "0.9rem", marginTop: "2%" }}>
                                O token deve conter 6 digitos
                            </p>
                        )}
                        <Button
                            onClick={() => VerificarQRCode()}
                            style={{
                                width: "48%",
                                height: "fit-content",
                                padding: "3%",
                                marginTop: "5px",
                            }}
                        >
                            Verificar
                        </Button>
                    </PopUp>
                </>
            )}

            {flag === true ? (
                <Container>
                    <ContainerEsquerda>
                        <Picture src={teste2} />
                    </ContainerEsquerda>
                    <ContainerDireita>
                        <Logo src={logo} />

                        <Form onSubmit={handleSubmit}>
                            <Campo>
                                <Label>CPF</Label>
                                <Input
                                    type="text"
                                    value={cpf}
                                    onChange={onChangeCpf}
                                    placeholder="insira seu CPF"
                                />
                            </Campo>
                            <Campo>
                                <Label>Senha</Label>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={onChangePassword}
                                    placeholder="insira sua senha"
                                />
                            </Campo>

                            <div
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    width: "50%",
                                    position: "relative",
                                }}
                            >
                                <button
                                    onClick={() => setModalAlterarSenha(true)}
                                    type="button"
                                    style={{
                                        fontSize: "0.9rem",
                                        textWrap: "nowrap",
                                        backgroundColor: "transparent",
                                        border: "none",
                                    }}
                                >
                                    Esqueceu a senha?
                                </button>
                                <Button type="submit">Entrar</Button>
                            </div>
                        </Form>
                        <FooterLogin>
                            <Creditos>
                                ® Grupo DEV - GPD / FUNDO ESTADUAL DE EDUCAÇÃO
                            </Creditos>
                        </FooterLogin>
                    </ContainerDireita>
                </Container>
            ) : (
                false
            )}
        </>
    );
}

export default Login;