import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import * as S from "./formConsulta.style.jsx";
import axios from "axios";
import Extrato from "../Extrato/extrato.jsx";
import ExtratoFI from "../ExtratoFundoInvestimento/extratoFundoInvestimento.jsx";
import Select from "react-select";
import PdfContaCorrente from "../../reports/extrato.jsx";
import { toast } from "sonner";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import { FormConsultaExtrato, HeaderExtrato } from "../../pages/Extrato/Extrato.style.jsx";
import { InputsExtrato } from "../../pages/Extrato/InputsExtrato/InputsExtrato.jsx";
import { ButtonExtrato } from "../../pages/Extrato/Button/Button.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { Button } from "../globalComponents.style.jsx";
import PdfFI from "../../reports/extratoAplicacaoFinanceira.jsx";
import { IoPrintSharp } from "react-icons/io5";

//funcao para obter o ultimo dia do mes atual
function ultimoDiaDoMes(ano, mes) {
    // Cria uma instância de data para o primeiro dia do próximo mês
    const primeiroDiaProximoMes = new Date(ano, mes, 1);
    // Subtrai um dia para obter o último dia do mês atual
    const ultimoDiaMesAtual = new Date(primeiroDiaProximoMes - 1);
    return ultimoDiaMesAtual.getDate();
}

//funcao para obter o primeiro dia do mes seguinte
function primeiroDiaDoMes(ano, mes) {
    // Cria uma instância de data para o primeiro dia do mês
    const primeiroDia = new Date(ano, mes, 1);
    return primeiroDia.getDate();
}

// funcao para consultar o ContaCorrente"
async function ExtratoProgramaGoverno(
    dataInicial,
    dataFinal,
    operacao,
    agencia,
    conta,
    token
) {
    const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
    const diaInicioFormatado = diaInicio < 10 ? `0${diaInicio}` : diaInicio;

    const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
    const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

    const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
    const mesInicioFormatado = mesInicio < 10 ? `0${mesInicio}` : mesInicio;
    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
    const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
    // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
    const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;
    if (mesFim > mesInicio) {
        if (30 - diaInicio + diaFim < 32) {
            let data = JSON.stringify({
                agencia: agencia.replace(/.$/, ""),
                conta: conta.replace(/.$/, ""),
                dataIncio:
                    dataInicial.getFullYear() +
                    "-" +
                    mesInicioFormatado +
                    "-" +
                    diaInicioFormatado,
                dataFim:
                    dataFinal.getFullYear() +
                    "-" +
                    mesFimFormatado +
                    "-" +
                    diaFimFormatado,
            });

            let config = {
                method: "post",
                url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: data,
            };
            const resp = await axios(config)
                .then(function (response) {
                    console.log(response.data)
                    return JSON.stringify(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

            if (resp) {
                return [JSON.parse(resp), true, true];
            }
        } else {
            let intervalo = mesFim - mesInicio;
            let primeiro = true;
            var resposta = {};
            let i = 0;
            for (; intervalo > 0; intervalo--, ++i) {
                if (primeiro) {
                    primeiro = false;
                    const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                    const diaInicioFormatado =
                        diaInicio < 10 ? `0${diaInicio}` : diaInicio;
                    const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesInicioFormatado =
                        mesInicio < 10 ? `0${mesInicio}` : mesInicio;

                    const diaFim = ultimoDiaDoMes(dataInicial.getFullYear(), mesInicio); // Obtém o dia do mês para dataFim
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                    const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesFim = mesInicio; // Obtém o mês para dataFim
                    // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
                    const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

                    let data = JSON.stringify({
                        agencia: agencia.replace(/.$/, ""),
                        conta: conta.replace(/.$/, ""),
                        dataIncio:
                            dataInicial.getFullYear() +
                            "-" +
                            mesInicioFormatado +
                            "-" +
                            diaInicioFormatado,
                        dataFim:
                            dataFinal.getFullYear() +
                            "-" +
                            mesFimFormatado +
                            "-" +
                            diaFimFormatado,
                    });

                    let config = {
                        method: "post",
                        url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "pdfi/json",
                        },
                        data: data,
                    };
                    const resp = await axios(config)

                        .then(function (response) {
                            console.log(response.data)
                            return JSON.stringify(response.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    if (resp && JSON.parse(resp).data !== "sem dados") {
                        resposta = JSON.parse(resp);
                    }
                }
                if (intervalo === 1) {

                    const diaInicio = primeiroDiaDoMes(
                        dataFinal.getFullYear(),
                        dataFinal.getMonth()
                    ); // Obtém o dia do mês para dataIncio
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda

                    const diaInicioFormatado =
                        diaInicio < 10 ? `0${diaInicio}` : diaInicio;
                    const mesInicio = dataFinal.getMonth() + 1; // Obtém o  mês para dataIncio
                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesInicioFormatado =
                        mesInicio < 10 ? `0${mesInicio}` : mesInicio;

                    const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                    const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
                    // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
                    const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

                    let data = JSON.stringify({
                        agencia: agencia.replace(/.$/, ""),
                        conta: conta.replace(/.$/, ""),
                        dataIncio:
                            dataInicial.getFullYear() +
                            "-" +
                            mesInicioFormatado +
                            "-" +
                            diaInicioFormatado,
                        dataFim:
                            dataFinal.getFullYear() +
                            "-" +
                            mesFimFormatado +
                            "-" +
                            diaFimFormatado,
                    });

                    let config = {
                        method: "post",
                        url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };

                    const resp = await axios(config)
                        .then(function (response) {
                            return JSON.stringify(response.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    if (resp && JSON.parse(resp).data !== "sem dados") {
                        let valor = JSON.parse(resp);
                        if (Object.keys(resposta).length === 0) {
                            resposta = JSON.parse(resp);
                        } else {
                            resposta.transactions.push(...valor.transactions);
                        }
                        return [resposta, true, true];
                    }
                    if (Object.keys(resposta).length === 0) {
                        console.log(resp);
                        resposta = JSON.parse(resp);
                        return [resposta, true, true];
                    }
                    return [resposta, true, true];
                } else {
                    const diaInicio = primeiroDiaDoMes(
                        dataInicial.getFullYear(),
                        dataInicial.getMonth() + i
                    ); // Obtém o dia do mês para dataIncio
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                    const diaInicioFormatado =
                        diaInicio < 10 ? `0${diaInicio}` : diaInicio;

                    const mesInicio = dataInicial.getMonth() + 2 + i; // Obtém o  mês para dataIncio
                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesInicioFormatado =
                        mesInicio < 10 ? `0${mesInicio}` : mesInicio;

                    const diaFim = ultimoDiaDoMes(
                        dataInicial.getFullYear(),
                        dataInicial.getMonth() + 2 + i
                    ); // Obtém o dia do mês para dataFim
                    // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                    const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

                    // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                    const mesFim = dataInicial.getMonth() + 2 + i; // Obtém o mês para dataFim
                    // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
                    const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

                    let data = JSON.stringify({
                        agencia: agencia.replace(/.$/, ""),
                        conta: conta.replace(/.$/, ""),
                        dataIncio:
                            dataInicial.getFullYear() +
                            "-" +
                            mesInicioFormatado +
                            "-" +
                            diaInicioFormatado,
                        dataFim:
                            dataFinal.getFullYear() +
                            "-" +
                            mesFimFormatado +
                            "-" +
                            diaFimFormatado,
                    });
                    let config = {
                        method: "post",
                        url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };

                    const resp = await axios(config)
                        .then(function (response) {
                            return JSON.stringify(response.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    if (resp && JSON.parse(resp).data !== "sem dados") {
                        let valor = JSON.parse(resp);
                        if (Object.keys(resposta).length === 0) {
                            resposta = JSON.parse(resp);
                        } else {
                            resposta.transactions.push(...valor.transactions);
                        }
                    }
                }
            }
        }
    }
}

const FormConsulta2 = (props) => {
    const [selectedOption, setSelectedOption] = useState("none");
    const [options, setOptions] = useState([]);
    const [loadedEntidade, setLoadedEntidade] = useState(true);
    const [selectedOptionOperacao, setSelectedOptionOperacao] = useState("none");
    // eslint-disable-next-line
    const [saldoContaCorrente, setSaldoContaCorrente] = useState("none");
    const [saldoAplicacao, setSaldoAplicacao] = useState("none");
    // eslint-disable-next-line
    const [flag, setFlag] = useState(false);
    // eslint-disable-next-line
    const [flagExtrato, setFlagExtrato] = useState(false);
    const [dados, setDados] = useState({});
    const [dadosFI, setDadosFI] = useState([]);
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [loading, setLoading] = useState(false);
    const [primeiroMes, setPrimeiroMes] = useState(null);
    const [ultimoMes, setUltimoMes] = useState(null);
    const [unidade, setUnidade] = useState();

    const { draesPermitidas, direcsPermitidas, escolasPermitidas } = useMyPermissoesContext();

    const { token } = useMyUserContext();

    const consultarSaldoAplicaçãoFinanceira = async () => {
        console.log("consultando saldo aplicação financeira")
        const { agencia, conta } = selectedOption;

        let data = JSON.stringify({
            agencia: agencia?.replace(/.$/, ""),
            conta: conta?.replace(/.$/, ""),
        });

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/SaldoAplicacaoFinanceira/`,
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        const valor = await axios(config)
            .then(function (response) {
                console.log(response.data)
                return JSON.stringify(response.data);
            })
            .catch(function (error) {
                toast.error("Erro ao consultar saldo de aplicação financeira");
                if (error.response.status == 400) {
                }
            }
            );

        if (valor) {
            setSaldoAplicacao(JSON.parse(valor));
            setFlag(true);
        }
    };

    const consultarContaCorrente = async () => {
        const { agencia, conta } = selectedOption;

        let data = JSON.stringify({
            agencia: agencia?.replace(/.$/, ""),
            conta: conta?.replace(/.$/, ""),

        });

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/SaldoContaCorrente/`,
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        const valor = await axios(config)
            .then(function (response) {

                return JSON.stringify(response.data);
            })
            .catch(function (error) {
                toast.error("Erro ao consultar saldo de aplicação financeira");
                if (error.response.status == 400) {
                }
            });

        if (valor) {
            setSaldoContaCorrente(JSON.parse(valor));
            setFlag(true);
        }
    };

    const realizarConsulta = async () => {
        if (!dataInicial || dataFinal === "") {
            toast.error("Preencha todos os campos");
            return
        }


        setSaldoAplicacao("none");
        setSaldoContaCorrente("none");
        setDados({});
        setLoading(true);
        //consultar conta corrente
        consultarContaCorrente();
        consultarSaldoAplicaçãoFinanceira();

        setFlag(false);
        console.log(selectedOptionOperacao)
        console.log(selectedOption.cnpj)
        console.log(selectedOption.convenio)
        if (
            selectedOption.cnpj &&
            selectedOption.convenio
        ) {

            const { agencia, conta } = selectedOption;



            if (selectedOptionOperacao === "ExtratoPoupancaOrgaoRepasse") {
                console.log("consultando ExtratoPoupancaOrgaoRepasse")

                let data = JSON.stringify({
                    agencia: agencia.replace(/.$/, ""),
                    conta: conta.replace(/.$/, ""),
                    variacao: 51,
                    mes: dataInicial.getMonth() + 1,
                    ano: dataInicial.getFullYear(),
                });

                console.log(data)

                let config = {
                    method: "post",
                    url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
                    headers: {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    data: data,
                };

                const resp = await axios(config)
                    .then(function (response) {
                        console.log(response.data)
                        return JSON.stringify(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                if (resp) {
                    setDados(JSON.parse(resp));
                    setFlag(true);
                    setFlagExtrato(true);
                }
            }

            if (selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno") {
                const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
                // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                const diaInicioFormatado =
                    diaInicio < 10 ? `0${diaInicio}` : diaInicio;

                const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
                // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
                const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

                const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
                // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                const mesInicioFormatado =
                    mesInicio < 10 ? `0${mesInicio}` : mesInicio;
                // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
                const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
                // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
                const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

                if (mesFim > mesInicio) {
                    const [data, flag1, flag2] = await ExtratoProgramaGoverno(
                        dataInicial,
                        dataFinal,
                        selectedOptionOperacao,
                        agencia,
                        conta,
                        token
                    );
                    setDados(data);
                    setFlag(flag1);
                    setFlagExtrato(flag2);
                } else {
                    let data = JSON.stringify({
                        agencia: agencia?.replace(/.$/, ""),
                        conta: conta?.replace(/.$/, ""),
                        dataIncio:
                            dataInicial.getFullYear() +
                            "-" +
                            mesInicioFormatado +
                            "-" +
                            diaInicioFormatado,
                        dataFim:
                            dataFinal.getFullYear() +
                            "-" +
                            mesFimFormatado +
                            "-" +
                            diaFimFormatado,
                    });
                    console.log(data)
                    let config = {
                        method: "post",
                        url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    const resp = await axios(config)
                        .then(function (response) {
                            console.log(response.data)
                            return JSON.stringify(response.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    if (resp) {
                        setDados(JSON.parse(resp));
                        setFlag(true);
                        setFlagExtrato(true);
                    }
                }
            }
            setFlag(false);

            if (
                selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
            ) {
                setDadosFI([]);
                setPrimeiroMes(dataInicial.getMonth() + 1);
                setUltimoMes(dataFinal.getMonth() + 1);
                let resposta = [];

                const extrairDados = async (mes) => {
                    const data = JSON.stringify({
                        agencia: agencia.replace(/.$/, ""),
                        conta: conta.replace(/.$/, ""),
                        codFI: 1972,
                        mes,
                        ano: dataInicial.getFullYear(),
                    });

                    console.log(data)
                    const config = {
                        method: "post",
                        url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };

                    try {
                        const response = await axios(config);
                        console.log(response.data)
                        return response;
                        // setFlagExtrato(true);
                    } catch (error) {
                        console.log(error);
                    }
                };

                if (dataFinal.getMonth() + 1 > dataInicial.getMonth() + 1) {
                    for (
                        let i = 0;
                        dataInicial.getMonth() + 1 + i <= dataFinal.getMonth() + 1;
                        i++
                    ) {
                        resposta.push(await extrairDados(dataInicial.getMonth() + 1 + i));
                    }
                    setDadosFI(resposta);
                } else {
                    resposta.push(await extrairDados(dataInicial.getMonth() + 1));
                    setDadosFI(resposta);
                }
            }
        }

    };


    var optionsUnidades = []

    if (direcsPermitidas.length > 0) {
        optionsUnidades.push({ value: "DIREC", label: "DIREC" });
    }
    if (draesPermitidas.length > 0) {
        optionsUnidades.push({ value: "DRAE", label: "DRAE" });
    }
    if (escolasPermitidas.length > 0) {
        optionsUnidades.push({ value: "ESCOLA", label: "ESCOLA" });
    }

    useEffect(() => {
        var selectEntidades = []
        switch (unidade) {
            case "DIREC":
                selectEntidades = direcsPermitidas;
                break;
            case "DRAE":
                selectEntidades = draesPermitidas;
                break;
            case "ESCOLA":
                selectEntidades = escolasPermitidas;
                break;
            default:
                break;
        }

        const newOptions = selectEntidades?.map((entidade, index) => ({
            value: entidade,
            label: `${entidade.cnpj} - ${entidade.nome} - ${entidade.convenio}`,
            key: index,
        }));

        setOptions(newOptions);
        setLoadedEntidade(false);
    }, [unidade]);

    const optionsOperacao = [
        {
            value: "ExtratoOrgaoRepasseProgramaDoGoverno",
            label: "Conta Corrente Orgão Repasse",
        },
        {
            value: "ExtratoFundosInvestimentoOrgaoRepasse",
            label: "Fundo Investimento Orgão Repasse",
        },
    ];
    const handleChange = useCallback((option) => {
        setSelectedOption(option ? option.value : null);
    }, []);

    const selectedValue = useMemo(() => {
        return options.find(option => option.value === selectedOptionOperacao);
    }, [options, selectedOptionOperacao]);
    return (
        <>
            {/* <S.ContainerFormConsulta> */}
            <HeaderExtrato>

                <div style={{
                    display: "flex", flexDirection: "column", height: "100%", width: "fit-content",
                    justifyContent: "end"
                }}>
                    <FormConsultaExtrato onSubmit={(e) => {
                        e.preventDefault();
                        realizarConsulta();
                    }}>
                        <InputsExtrato
                            type={1}
                            options={optionsUnidades}
                            onChange={(option) => setUnidade(option ? option.value : null)}
                            value={optionsUnidades.find(option => option.value === unidade)}
                            placeholder="Escolha uma unidade"
                        />

                        <InputsExtrato
                            label="CNPJ"
                            type={2}
                            options={options}
                            placeholder="Selecione um CNPJ"
                            MenuPortal
                            onChange={handleChange}
                            value={selectedValue} />

                        <InputsExtrato
                            label="OPERAÇÃO"
                            type={2}
                            options={optionsOperacao}
                            placeholder="Selecione uma operação"
                            onChange={(option) => setSelectedOptionOperacao(option ? option.value : null)}
                            value={options.find(option => option.value === selectedOptionOperacao)} />

                        <InputsExtrato
                            label="DATA DE INÍCIO"
                            type={2}
                            isDate={true}
                            id="dataInicial"
                            selected={dataInicial}
                            date={dataInicial}
                            placeholder="Data inicial"
                            onChange={(date) => setDataInicial(date)} />

                        <InputsExtrato
                            label="DATA DE FIM"
                            type={2}
                            isDate={true}
                            id="dataFinal"
                            selected={dataFinal}
                            date={dataFinal}
                            placeholder="Data final"
                            onChange={(date) => setDataFinal(date)}
                            minDate={dataInicial} />

                        <ButtonExtrato />
                    </FormConsultaExtrato>
                    <span>Extrato Bancário</span></div>


                <S.ContainerSaldo>
                    <S.LabelSaldo
                        titulo="Saldo Conta Corrente:"
                        valor={saldoContaCorrente.valorDisponibilidade}
                        loading={loading}
                    />
                    <S.LabelSaldo
                        titulo="Saldo Aplicação:"
                        valor={saldoAplicacao.valorDisponibilidade}
                        loading={loading}
                    />

                    <div style={{
                        width: '100%', height: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                    }}>
                        <Button
                            primary
                            width="100%"
                            height="50%"
                            onClick={() =>
                                selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
                                    ? PdfFI({ dadosFI, selectedOption })
                                    : selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno"
                                        ? PdfContaCorrente({ dados, selectedOption })
                                        : toast.warning('Realize uma consulta')
                            }
                        >
                            <IoPrintSharp /> Imprimir
                        </Button>
                    </div>
                </S.ContainerSaldo>

            </HeaderExtrato>

            {/* */}
            {/* */}
            {/* </S.ContainerFormConsulta > */}
            <S.ContainerFormConsulta2
            >
                {loading ? (
                    flag ? (
                        dadosFI.length > 0 ? (
                            <ExtratoFI
                                data={dadosFI}
                                mesIncio={primeiroMes}
                                mesFim={ultimoMes}
                            />
                        ) : (
                            <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                                <div class="spinner-border" role="status" />

                            </div>
                        )
                    ) : Object.keys(dados).length > 0 ? (
                        <Extrato data={dados} />
                    ) : (
                        <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                            <div class="spinner-border" role="status" />

                        </div>
                    )
                ) : (
                    false
                )}
            </S.ContainerFormConsulta2>
        </>
    );
};

export default FormConsulta2;
