//import React from "react";
import "./extrato.css";

function ResponsiveExample(props) {
  let i = 0;
  function renderizaTabela(dados) {
    let mes = props.mesIncio + i;
    i++;
    const array1 = ["a", "b", "c"];
    if (dados.data.data === "sem dados") {
      return (
        <div>
          <h1>Extrato Fundo de Investimento</h1>
          <p>
            Mês {mes} - {dados.data.data}
          </p>
        </div>
      );
    } else {
      const tabelaRows = array1.map((element, index) => {
        if (element === "a") {
          return (
            // Imprime a primeira linha da tabela
            <tr key={index}>
              <td>{dados.data.extrato.dataSaldoAnterior}</td>
              <td>SALDO ANTERIOR</td>
              <td>
                {dados.data.extrato.valorSaldoAnterior.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
              <td></td>
              <td></td>
              <td>
                {dados.data.extrato.quantidadeCotaAnterior.toLocaleString(
                  "pt-BR",
                  { minimumFractionDigits: 6, maximumFractionDigits: 6 }
                )}
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
        if (element === "b") {
          // Imprime as linhas do meio da tabela
          return dados.data.extrato.listaLancamentosExtrato.map(
            (dado2, index) => (
              <tr key={index}>
                <td>{dado2.dataLancamento}</td>
                <td>{dado2.descricao}</td>
                <td>
                  {dado2.valorLancamento.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td>
                  {dado2.valorIR + dado2.valorPrejuizo > 0 &&
                    (dado2.valorIR + dado2.valorPrejuizo).toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "BRL" }
                    )}
                </td>
                <td>
                  {dado2.valorIOF > 0 &&
                    dado2.valorIOF.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </td>
                <td>
                  {dado2.quantidadeCota.toLocaleString("pt-BR", {
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
                </td>
                <td>
                  {" "}
                  {dado2.valorCota > 0 &&
                    dado2.valorCota.toLocaleString("pt-BR", {
                      minimumFractionDigits: 9,
                      maximumFractionDigits: 9,
                    })}
                </td>
                <td>
                  {dado2.saldoCotas > 0 &&
                    dado2.saldoCotas.toLocaleString("pt-BR", {
                      minimumFractionDigits: 6,
                      maximumFractionDigits: 6,
                    })}
                </td>
              </tr>
            )
          );
        }
        if (element === "c") {
          return (
            // Imprime a última linha da tabela
            <tr key={dados.id}>
              <td>{dados.data.extrato.dataSaldoMesAnterior}</td>
              <td>SALDO ATUAL</td>
              <td>
                {dados.data.extrato.valorSaldoMesAnterior.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </td>
              <td></td>
              <td></td>
              <td>
                {dados.data.extrato.quantidadeCotaMesAnterior.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </td>
              <td></td>
              <td>
                {dados.data.extrato.quantidadeCotaMesAnterior.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </td>
            </tr>
          );
        }
        return null;
      });
      return (
        <div className="tableDiv">
          <p className="title" style={{ display: "flex", alignSelf: "center", color: "#0a7bbd" }}>Mês {mes}</p>
          <table>
            <thead>
              <tr style={{ backgroundColor: "white" }}>
                <th>Data</th>
                <th>Histórico</th>
                <th>Valor</th>
                <th>Valor IRPrej. Comp.</th>
                <th>Valor IOF</th>
                <th>Quantidade cotas</th>
                <th>Valor cota</th>
                <th>Saldo cotas</th>
              </tr>
            </thead>
            <tbody>{tabelaRows}</tbody>
          </table>

          <p className="title">Resumo do mês</p>
          <div className="row">
            <div className="column1">
              <div className="row1">SALDO ANTERIOR</div>
              <div className="row1">APLICAÇÕES (+)</div>
              <div className="row1">RESGATES(-)</div>
              <div className="row1">RENDIMENTO BRUTO(+)</div>
              <div className="row1">IMPOSTO DE RENDA(-)</div>
              <div className="row1">IOF(-)</div>
              <div className="row1">RENDIMENTO LIQUIDO(-)</div>
              <div className="row1">SALDO ATUAL</div>
            </div>
            <div className="column2">
              <div className="row1">
                {dados.data.extrato.valorSaldoAnterior.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
              <div className="row1">
                {dados.data.extrato.valorTotalAplicadoPeriodo.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorTotalResgatadoPeriodo.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorRendimentoBrutoPeriodo.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorTotalIRPeriodo.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorTotalIOFPeriodo.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorRendimentoLiquido.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorSaldoMesAnterior.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                )}
              </div>
            </div>
          </div>
          <p className="title">Valor da Cota</p>
          <div className="row">
            <div className="column1">
              <div className="row1">
                {dados.data.extrato.dataAfericaoValorCota}
              </div>
              <div className="row1">
                {dados.data.extrato.dataUltimaCotacaoCota}
              </div>
            </div>
            <div className="column2">
              <div className="row1">
                {dados.data.extrato.valorCota.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
              <div className="row1"></div>
            </div>
          </div>
          <p className="title">Rentabilidade</p>
          <div className="row">
            <div className="column1">
              <div className="row1">No mês</div>
              <div className="row1">No ano</div>
              <div className="row1">último 12 meses</div>
            </div>
            <div className="column2">
              <div className="row1">
                {dados.data.extrato.valorRentabilidadeMes.toLocaleString(
                  "pt-BR",
                  { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorRentabilidadeAno.toLocaleString(
                  "pt-BR",
                  { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                )}
              </div>
              <div className="row1">
                {dados.data.extrato.valorRentabilidadeResgateTotal.toLocaleString(
                  "pt-BR",
                  { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="containerCentral">
      <h1>
        <span>Extrato Aplicação Financeira - Convênio{" "}
        {props.data[0]?.data?.nomeProgramaGoverno} </span>
        <span></span>
        <span></span>
      </h1>
      {props.data.map((dados) => renderizaTabela(dados))}
    </div>
  );
}

export default ResponsiveExample;
