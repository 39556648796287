/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, Container, ContainerBox, ContainerInfo, GridPerfil, Section } from "./Dashboard.style.jsx";
import Title from "./components/Title/Title.jsx";
import ContainerBoxLabel from "./components/ContainerBoxLabel/ContainerBoxLabel.tsx";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import { InfoBox } from "./components/UnidadeInfoBox/InfoBox.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";

function Dashboard() {

  const { usuarioLogado, usuariosNoSetor } = useMyUserContext();
  const { direcsPermitidas, escolasPermitidas, draesPermitidas } = useMyPermissoesContext();
  const [infoBoxClicked, setInfoBoxClicked] = useState("");

  return (
    <Container>
      <Section height="70%">
        <Title title="PÁGINA INICIAL" />
        <ContainerBox>
          <Box width="30%">
            <GridPerfil>
              <ContainerBoxLabel title="NOME" content={usuarioLogado?.nome} />
              <ContainerBoxLabel title="PERFIL" content={usuarioLogado?.permissoes[0].nome} />
              <ContainerBoxLabel title="RELATÓRIO" content={usuarioLogado?.relatorio ? "COM ACESSO" : "SEM ACESSO"} />
              <ContainerBoxLabel title="SETOR" content={usuarioLogado?.setor} />
              <ContainerBoxLabel title="TELEFONE" content={usuarioLogado?.telefone} />
              <ContainerBoxLabel title="EMAIL" content={usuarioLogado?.email} />
            </GridPerfil>

          </Box>
          <Box width="70%">
            <ContainerInfo>
              <InfoBox
                titulo="ESCOLAS ATIVAS"
                dado={(escolasPermitidas.length / 3).toFixed()}
                message={`Quantidade de escolas ativas nessa unidade ${usuarioLogado?.setor}.`}
                onClick={() => setInfoBoxClicked("")}
              />
              <InfoBox
                titulo="PESSOAS NO SETOR" dado={usuariosNoSetor?.length}
                message={`Quantidade de pessoas ativas nessas unidade ${usuarioLogado?.setor}.`}
                onClick={() => setInfoBoxClicked("PESSOAS NO SETOR")} />
              {/* <InfoBox titulo="DIRETORES" dado={78} message={"Quantidade de escolas ativas nessa unidade."} />
              <InfoBox titulo="ESCOLAS ATIVAS" dado={26} message={"Quantidade de escolas ativas nessa unidade."} /> */}
            </ContainerInfo>

            {infoBoxClicked === "" && (
              <div style={{
                display: "flex", height: "70%",
                overflow: "auto", justifyContent: "start", alignItems: "start",
                fontSize: "0.8rem", gap: "1rem", padding: "0 1rem"
              }}>
                <table style={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "start" }}>
                  <thead>
                    <tr>
                      <th>Escola</th>
                      <th>CNPJ</th>
                      <th>FEE</th>
                      <th>MUNICÍPIO</th>
                      <th>DIREC</th>
                      <th>DRAE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {escolasPermitidas.map((escola, index) => {
                      return (
                        <tr key={index}>
                          <td>{escola.nome}</td>
                          <td>{escola.cnpj}</td>
                          <td>{escola.fee}</td>
                          <td>{escola.municipio}</td>
                          <td>{escola.direc.nome}</td>
                          <td>{escola.drae.nome}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {(infoBoxClicked === "PESSOAS NO SETOR" && (usuariosNoSetor?.length > 0)) && (
              <div style={{
                display: "flex", height: "70%",
                overflow: "auto", justifyContent: "start", alignItems: "start",
                fontSize: "0.8rem", gap: "1rem", padding: "0 1rem"
              }}>
                <table style={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "start" }}>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Telefone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usuariosNoSetor.map((escola, index) => {
                      return (
                        <tr key={index}>
                          <td>{escola.nome}</td>
                          <td>{escola.telefone}</td>
                          <td>{escola.email}</td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </Box>
        </ContainerBox>
      </Section>

      <Section height="30%">
        <Title title="CONTATOS" />
        <ContainerBox>
          <Box width="100%">
            <div style={{
              display: "flex", gap: "2rem", padding: "0 1rem "
            }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <span style={{ fontSize: "0.9rem", fontWeight: "bold", color: "#585858" }}> Canal oficial de suporte:</span>
                <span>tigpd@educar.rn.gov.br</span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                <span style={{ fontSize: "0.9rem", fontWeight: "bold", color: "#585858" }}> Fundo Estadual da Educação:</span>
                <span>feecontas@hotmail.com</span>

              </div>

            </div>

          </Box>
        </ContainerBox>
      </Section>
    </Container >
  );

}

export default Dashboard;
