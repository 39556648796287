import styled from "styled-components";


export const HeaderStyle = styled.div`
display: flex;
height: 2.5rem;
width: 100%;;
background-color: ${props=> props.theme.color.bg[200]};
padding-left: 1rem;
align-items: center;
font-weight: 600;
color: ${props=> props.theme.color.primary};
border-bottom: 1px solid ${props=> props.theme.color.bg[100]};
font-size: 1.3rem;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 1.3rem;
  display: flex;
  color: #373737;
  text-align: center;
  margin-bottom: 5px;
`;
