import React, { useState, useContext, createContext, useEffect } from "react";
import { verificarPermissoesDirec } from "./DirecPermissoesCheck";
import { verificarPermissoesDrae } from "./DraePermissoesCheck";
import {
    verificarPermissoesEscola
} from "./EscolaPermissoesCheck";
import { useMyUserContext } from "../MyUserContext.tsx";

//criando o context
export const MyPermissoesContext = createContext({
    permissoes: [],
    direcsPermitidas: [],
    draesPermitidas: [],
    escolasPermitidas: [],
    EscolasPaguePredial: [],
    EscolasPagueOrdinario: [],
    EscolasMaisAlimentacao: [],
    apagarTodasAsPermissoes: () => { },
    verificarPermissoesDoUsuario: () => { },
    setDraesPermitidas: () => { },
    setDirecsPermitidas: () => { },
    setEscolasPermitidas: () => { }
});

//utilizar o context
export const useMyPermissoesContext = () => {
    return useContext(MyPermissoesContext);
};

//envolver o objeto no context
export function MyPermissoesContextProvider({ children }) {
    const [permissoes, setPermissoes] = useState([]);
    const [direcsPermitidas, setDirecsPermitidas] = useState([])
    const [draesPermitidas, setDraesPermitidas] = useState([])
    const [escolasPermitidas, setEscolasPermitidas] = useState([]);
    const [EscolasPaguePredial, setEscolasPaguePredial] = useState([]);
    const [EscolasPagueOrdinario, setEscolasPagueOrdinario] = useState([]);
    const [EscolasMaisAlimentacao, setEscolasMaisAlimentacao] = useState([]);

    const { usuarioLogado, token } = useMyUserContext();


    const verificarPermissoesDoUsuario = async () => {
        if (usuarioLogado?.permissoes) {
            if (usuarioLogado.relatorio === true) {
                setPermissoes([...permissoes, "RELATORIO"])
            }
            if (usuarioLogado.role === "ADMIN") {
                setPermissoes([...permissoes, "ADMIN"])
            }
            usuarioLogado?.permissoes.forEach((permissao) => {
                setPermissoes([...permissoes, permissao.nome])

                if (permissao.programaDirec !== null && permissao.programaDirec !== "") {
                    verificarPermissoesDirec(permissao.nome, permissao.programaDirec, usuarioLogado?.direc?.id, token).then((res) => {
                        res?.sort((a, b) => a.nome.localeCompare(b.nome))
                        setDirecsPermitidas(res);
                    });
                }

                if (permissao.programaDrae !== null && permissao.programaDrae !== "") {
                    verificarPermissoesDrae(permissao.nome, permissao.programaDrae, usuarioLogado?.drae?.id, token).then((res) => {
                        res?.sort((a, b) => a.nome.localeCompare(b.nome))
                        setDraesPermitidas(res)
                    })
                }
                if (permissao.programaEscola !== null && permissao.programaEscola !== "") {
                    verificarPermissoesEscola(permissao.nome, usuarioLogado?.direc?.id, usuarioLogado?.drae?.id, permissao.programaEscola, token).then((res) => {
                        res?.sort((a, b) => a.nome.localeCompare(b.nome))
                        setEscolasPermitidas(res);
                    })
                }
            })
        } else {
            setPermissoes([])
            setDirecsPermitidas([])
            setDraesPermitidas([])
            setEscolasPermitidas([])
        }
    }

    function apagarTodasAsPermissoes() {
        setPermissoes([])
        setDirecsPermitidas([])
        setDraesPermitidas([])
        setEscolasPermitidas([])
        setEscolasPaguePredial([])
        setEscolasPagueOrdinario([])
        setEscolasMaisAlimentacao([])
    }
    return (
        <MyPermissoesContext.Provider
            value={{
                verificarPermissoesDoUsuario, permissoes,
                direcsPermitidas, draesPermitidas, escolasPermitidas,
                EscolasPaguePredial, EscolasPagueOrdinario,
                EscolasMaisAlimentacao, apagarTodasAsPermissoes,
                setDirecsPermitidas, setDraesPermitidas, setEscolasPermitidas
            }}
        >
            {children}
        </MyPermissoesContext.Provider>
    );
}
